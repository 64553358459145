import dayjs from 'dayjs';
import { useState } from 'react';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import RadioButton from '@/components/RadioButton';
import Text from '@/components/Text';
import { providerLicenses } from '@/utils/constants';
import { ProviderDetail, AppointmentV2 } from '@/utils/types';
import ProviderHeadshot from './ProviderHeadshot';

export interface ReplaceProviderModalProps {
  onClose: () => unknown;
  provider: ProviderDetail;
  appointmentInNextDay: AppointmentV2;
  onContinue: (shouldCancel: boolean) => unknown;
  cancellationFee: string;
  trackModalEvent: (
    userContinued: boolean,
    userCancelled?: null | boolean,
  ) => unknown;
}

const ReplaceProviderCancellationPromptModal = ({
  onClose,
  provider,
  onContinue,
  appointmentInNextDay,
  cancellationFee,
  trackModalEvent,
}: ReplaceProviderModalProps) => {
  const [cancelApptsInNextDay, setCancelApptsInNextDay] = useState(false);

  const cancelSelection = cancelApptsInNextDay ? 'yes' : 'no';
  const closeModalAndTrackUsage = () => {
    trackModalEvent(false, null);
    onClose();
  };

  const apptTime = appointmentInNextDay.start_time;

  // if there is an appointment with this provider in the next day, prompt
  // user to cancel appt or not
  return (
    <div className="rounded-2 p-6 space-y-6 bg-tertiary-0 max-w-[563px]">
      <div className="flex justify-between">
        <Text.H4>Do you want to cancel your final appointment?</Text.H4>
        <X onClick={closeModalAndTrackUsage} className="cursor-pointer"></X>
      </div>
      <div className="space-y-6">
        <Text.P className="text-tertiary-5">
          You have an appointment with {provider.first_name}{' '}
          {provider.last_name} which is less than 24 hours away. Do you want to
          cancel?
        </Text.P>
        <div className="border-1 border-tertiary-2 rounded-2">
          <div className="pt-3 pr-4 pb-3 pl-4 border-b-1 border-tertiary-2 flex justify-between items-center">
            <Text.P.Bold>
              {dayjs(apptTime).format('ddd MMM D [at] h:mmA')}
            </Text.P.Bold>
            <div className="bg-warning-0 pt-1 pr-2 pb-1 pl-2 rounded-[100px] flex items-center">
              <Text.Small className="text-warning-1">
                Final appointment
              </Text.Small>
            </div>
          </div>
          <div className="flex items-center p-4 gap-5">
            <ProviderHeadshot
              profileImage={provider.profile_image_s3_url}
              className="h-10 w-10"
            />
            <div className="space-y-1">
              <Text.P.Bold>
                {provider.first_name} {provider.last_name}
              </Text.P.Bold>
              <Text.P>{providerLicenses[provider.licenses[0]]?.name}</Text.P>
            </div>
          </div>
        </div>
        <div>
          <div className="border-b-1 border-tertiary-2 pb-4">
            <RadioButton
              label="No, I want to keep my final apppointment"
              name="cancelSelection"
              selectedValue={cancelSelection}
              value="no"
              onCheck={() => setCancelApptsInNextDay(false)}
            />
          </div>
          <div className="pt-4">
            {/* Rematch is only allowed for therapists, so no need to check provider role */}
            <RadioButton
              label={`Yes, I want to cancel my final appointment and pay the ${cancellationFee} cancellation fee`}
              name="cancelSelection"
              selectedValue={cancelSelection}
              value="yes"
              onCheck={() => setCancelApptsInNextDay(true)}
            />
          </div>
        </div>
      </div>
      <div className="flex md:justify-end justify-center w-full">
        <Button
          variant="primary"
          sizeClasses="md:w-[210px] w-full"
          onClick={() => {
            trackModalEvent(true, cancelApptsInNextDay);
            onContinue(cancelApptsInNextDay);
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default ReplaceProviderCancellationPromptModal;
