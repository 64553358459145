import { Dayjs } from 'dayjs';
import { ModalProps } from '@/components/Modal';
import { ToastProps } from '@/components/Toast';
import { providerLicenses, timeSlots } from './constants';
import { BannerProps } from '@/components/Banner';

export type ReactChildren = JSX.Element | null;

// Utility type to override Original with keys from OverrideType
export type Override<Original, OverrideType> = Omit<
  Original,
  keyof OverrideType
> &
  OverrideType;

export type JSONValue =
  | string
  | number
  | boolean
  | JSONObject
  | JSONArray
  | null;

export interface JSONObject extends Record<string, JSONValue> {
  [x: string]: JSONValue;
}

export type JSONArray = Array<JSONValue>;

export type AppointmentType =
  | 'INITIAL COUPLES THERAPY'
  | 'INITIAL FAMILY THERAPY'
  | 'FAMILY THERAPY'
  | 'INITIAL THERAPY VISIT'
  | 'INITIAL UNDER 13 THERAPY'
  | 'INITIAL PSYCH VISIT'
  | '60 MIN THERAPY'
  | 'FREE CONSULT'
  | 'FOLLOW UP PSYCH'
  | 'PSYCH TRANSFER';

export type BillingStatementRow = {
  providers: string[];
  link: string;
};

export type TherapyType = 'individual' | 'couples' | 'family' | 'psychiatric';
export type SeriesType = 'followup' | 'initial';

export type WillingToSee =
  | 'Individuals'
  | 'Couples'
  | 'Families'
  | 'Minors (13-17)'
  | 'Preteen (8-12)'
  | 'Children (5-7)';

export type Role = 'PRESCRIBER' | 'THERAPIST';

type Status = 'confirmed' | 'canceled' | 'held' | 'deleted' | 'seen';

export type AppointmentParticipant = {
  uuid: string;
  ref: string;
  path_service_region: string;
  appointment_uuid: string;
  created_at: string;
  role: string;
  data: {
    name: string;
    email: string;
    phone: string;
    birth_date: string;
  };
};
export interface AppointmentV2 {
  uuid: string;
  ref: string;
  therapy_type: TherapyType;
  series_type: SeriesType;
  series_root_uuid: string | null;
  recurrence: null | string;
  path_service_region: string;
  provider_ref: string;
  start_time: string;
  end_time: string;
  join_url: string;
  invite_url: string;
  npi: number;
  participants: [AppointmentParticipant];
  status: Status;
}

export type OldAppointmentProps = {
  isFirstAppointment: boolean;
  startTime: string;
  appointmentType: string;
  previousLengthInMinutes: number;
};

export type ProviderShape = {
  uuid: string;
  portal_account_active: boolean;
  earliest_active_start_time: string | null;
} & ProviderDetail;

export interface CareTeamV2 {
  active: ProviderShape[];
  inactive: ProviderShape[];
}

export interface ActiveTherapyType {
  therapy_type: TherapyType;
  earliest_active_start_time: string | null;
  latest_active_start_time: string | null;
  has_had_appointment: boolean;
  provider_uuid: string;
  patient_uuid: string;
  npi: string;
  is_confirmed: boolean;
}

export type ActiveProviderShapeV3 = {
  uuid: string;
  portal_account_active: boolean;
  active_therapy_types: ActiveTherapyType[];
} & ProviderDetail;

type DeactivationEventType =
  | 'lapsed_care'
  | 'rematch'
  | 'discharge'
  | 'not_seen'
  | 'churned';
export interface DeactivationEvent {
  provider_uuid: string;
  patient_uuid: string;
  occurred_at: string | null; // iso8601 time like: YYYY-MM-DDT13:00:00.000z
  npi: string;
  therapy_type: TherapyType;
  event_type: DeactivationEventType;
}

export type InactiveProviderShape = {
  uuid: string;
  portal_account_active: boolean;
  deactivation_events: DeactivationEvent[];
} & ProviderDetail;

export type ProviderWithFirstAppointmentInfo = (
  | ActiveProviderShapeV3
  | InactiveProviderShape
) & { has_had_appointment?: boolean; is_confirmed?: boolean };

export interface CareTeamV3 {
  active: ActiveProviderShapeV3[];
  inactive: InactiveProviderShape[];
}

export type ProviderRole = 'THERAPIST' | 'PRESCRIBER';

export type ProviderBase = {
  npi: number;
  first_name: string;
  last_name: string;
  profile_bio: string;
  licenses: (keyof typeof providerLicenses)[];
  languages: string[];
  profile_image_s3_url: string;
  profile_summary: string | undefined;
  slot_start_time: string;
};

export type ProviderDetail = ProviderBase & {
  active_in_two_letter_states: string[];
  start_year: number;
  education: string;
  willing_to_see: string[]; // TODO Sylvie - willing_to_see should maybe be a type or a const list?
  approved_client_email: string;
  approved_client_facing_email: boolean;
  preferred_name: string;
  profile_summary: string;
  profile_approach: string;
  profile_focus: string;
  profile_journey: string;
  profile_style: string;
  profile_goals: string;
  profile_first_session: string;
  pronouns: string;
  provider_stage: string;
  insurances: string[];
  allyships: string[];
  specializations: string[];
  top_specializations: string[];
  modalities: string[];
  top_modalities: string[];
  two_letter_state: string;
  role: ProviderRole;
  // bookable_slots is calculated in PPB. It's all of the provider's total available slots that
  // are 2 business days in the future to 28 days in the future (inclusive).
  // Do NOT use the base slots field unless you have a very specific reason to do so
  bookable_slots: number[];
  // bookable_followup_slots is used for prescribers, which currently
  // is the only case where it will be populated, and used for followup appointments
  bookable_followup_slots: number[];
  status: string;
};

export const isProviderDetail = (o: unknown): o is ProviderDetail =>
  !!(
    o &&
    typeof o === 'object' &&
    (o as ProviderDetail).npi &&
    (o as ProviderDetail).first_name
  );

export type ProviderLicenseDescription = {
  name?: string;
  priority?: number;
  description?: string;
};

export type RescheduleV2Params = {
  providerNpi: string;
  appointmentUUID: string;
  newAppointmentUnix?: string;
};

export type ProviderProfileParams = {
  therapyType: TherapyType;
  providerNpi: string;
};

export type RescheduleParams = ProviderProfileParams & {
  currentAppointmentId: string;
  newAppointmentUnix: string;
};

export type TimesOfDay = keyof GroupedSlotsForDay;

export type GroupedSlotsForDay = {
  Morning: Dayjs[];
  Afternoon: Dayjs[];
  Evening: Dayjs[];
};

export type SchedulingDay = {
  date: Dayjs;
  slots?: Dayjs[];
  GroupedSlotsForDay?: GroupedSlotsForDay;
  index: number;
};

export interface StatementRecord {
  provider_name: string;
  date_of_service: string;
}

export interface Statement {
  statement: StatementRecord;
  link: string;
}

export interface Payment {
  date_of_payment: string;
  external_id: number;
  payment_amount: string;
}

export interface Billing {
  statements: Statement[];
  balance: string;
  payments: Payment[];
}

export type PendingLoad = {
  loadingState: 'pending' | 'needed' | 'in_progress'; // data has not been loaded yet
};

export type Loaded<D> = {
  loadingState: 'done'; // data is loaded and present, and we don't think we need to load it again yet
} & D;

export type ServerData<D> =
  | PendingLoad
  | Loaded<D>
  | {
      loadingState: 'error'; // there was an error loading the data
    };

export function isLoaded<D>(d: ServerData<D>): d is Loaded<D> {
  return d.loadingState === 'done';
}

export function isEditing<D, E>(d: Editable<D, E>): d is Editing<D, E> {
  return d.editState.mode === 'edit';
}

export function isEditingProfile<D, E>(d: Editable<D, E>): d is Editing<D, E> {
  return d.editState.mode === 'edit' && !d.editState.ecEdit;
}

export function isEditingEmergencyContact<D, E>(
  d: Editable<D, E>,
): d is Editing<D, E> {
  return d.editState.mode === 'edit' && !!d.editState.ecEdit;
}

export type ExtractEditing<T> = Extract<T, { editState: { mode: 'edit' } }>;

export type Editing<D, E = D> = D & {
  editState: {
    mode: 'edit';
    edited: E;
    persistence: 'unsaved' | 'saving' | 'saved' | 'error';
    invalid?: boolean;
    ecEdit?: boolean;
  };
};

export type Reading<D> = D & { editState: { mode: 'read' } };

export type Editable<D, E> = Reading<D> | Editing<D, E>;

export type EditableProfileDetails = Pick<
  ProfileDetails,
  | 'address'
  | 'phone'
  | 'emergency_contact_name'
  | 'emergency_contact_phone'
  | 'preferred_name'
>;
export interface Address extends Record<string, string | undefined> {
  street: string;
  apt_suite: string | undefined;
  city: string;
  state: string;
  zip: string;
}

export type PaymentMethod = 'Self Pay' | 'Health Insurance';

export interface ProfileDetails {
  first_name: string;
  last_name: string;
  preferred_name: string;
  birthdate: string; // iso8601
  sex_assigned_at_birth: SexOnInsuranceCard;
  address: Address;
  email: string;
  location: string;
  phone: string;
  emergency_contact_name?: string;
  emergency_contact_phone?: string;
  uuid?: string;
}

export interface ProviderOptions {
  faiths?: string[];
  genders?: string[];
  insurances?: string[];
  languages?: string[];
  modalities?: string[];
  opento15?: string[];
  races?: string[];
  roles?: string[];
  sessions?: string[];
  specializations?: string[];
  status?: string[];
  willing_to_offer?: string[];
  willing_to_see?: string[];
}

export type SexOnInsuranceCard = 'Male' | 'Female' | undefined;

export type EditableInsuranceDetails = Pick<
  InsuranceDetails,
  | 'payment_method'
  | 'carrier'
  | 'subscriber_id'
  | 'relationship_to_insured'
  | 'sex_on_insurance_card'
  | 'effective_date'
> & {
  subscriber: {
    first_name: string;
    last_name: string;
    birthdate: string;
    gender: SexOnInsuranceCard;
    address: Address;
  };
  front_of_card: File | undefined;
  back_of_card: File | undefined;
};

export type InsuranceDetails = {
  payment_method: PaymentMethod;
  carrier: string | undefined;
  display_name: string | undefined;
  network_name: string | undefined;
  subscriber_id: string | undefined;
  relationship_to_insured: 'Self' | 'Spouse' | 'Other' | undefined;
  sex_on_insurance_card: SexOnInsuranceCard;
  effective_date?: string | undefined;
};

export type EditableCreditCardDetails = {
  number: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  billing_address: Address;
  expiration_date: string | undefined;
  cvv: string | undefined;
};

export type CreditCardDetails = {
  last_4_digits: string | undefined;
  card_brand: string | undefined;
  expiration_year: string | undefined;
  expiration_month: string | undefined;
};

export interface PatientData extends Record<string, number | string> {
  advanced_md_office_key: number;
  salesforce_contact_id: string;
  patient_record_uuid: string;
  patient_uuid: string;
}

export type EditingPageData = {
  [Key in keyof Data as Exclude<
    Key,
    Data[Key] extends Editable<unknown, unknown> ? undefined : Key
  >]: Data[Key] extends Editable<infer D, infer E> ? Editing<D, E> : undefined;
};

export type CarrierOption = {
  active: boolean;
  label: string;
  value: string;
};

export type MessageThreads = MessageThreadData[];

export type SelfPayRates = {
  couples: number;
  couples_family: number;
  family: number;
  individual: number;
  psych_cancel: number;
  psych_follow_up: number;
  psych_initial: number;
  therapy_cancel: number;
};

export interface Data {
  carriers: { options: CarrierOption[] };
  acceptedInsurances: { data: Record<string, string[]> };
  selfPayRates: { data: SelfPayRates };
  providerSpecializations: { data: Record<string, string[]> };
  providerModalities: { data: Record<string, string[]> };
  providerOptions: { data: ProviderOptions };
  profile: Editable<ProfileDetails, EditableProfileDetails>;
  advancedMdOfficeKeyStateMap: { data: Record<number, string> };
  insurance: Editable<InsuranceDetails, EditableInsuranceDetails>;
  upcomingInsurance: Editable<InsuranceDetails, EditableInsuranceDetails>;
  creditCard: Editable<CreditCardDetails, EditableCreditCardDetails>;
  patientData: PatientData;
  billing: Billing;
  appointments_v2: { rows: AppointmentV2[] };
  messaging: Messaging;
  careTeam_v3: CareTeamV3;
  surveys: { data: Survey[] };
}

interface State {
  toast: ToastProps | null;
}

export type UnauthenticatedUi = { modal?: ModalProps | null } & (
  | {
      status: 'magic_link_expired' | 'magic_link_used' | 'session_expired';
    }
  | {
      status: 'login';
      email: string;
      delivered: boolean;
      emailInvalid: boolean;
    }
  | {
      status: 'unverified' | 'unverified_phone' | 'unverified_birthdate';
      dob?: string;
      phoneNumber?: string;
      attempts?: string[];
      state?: undefined | 'checking' | 'success';
    }
  | {
      status: 'verified_account_not_selected';
    }
);

export interface UnauthenticatedState extends State {
  session: Record<never, never>;
  token?: string | null;
  ui: UnauthenticatedUi;
}

export type ProfilePageState = {
  path: 'profile';
  nameTooltipOpen?: boolean;
  preferredNameTooltipOpen?: boolean;
  emailTooltipOpen?: boolean;
  sexAtBirthTooltipOpen?: boolean;
  sexOnInsuranceCardTooltipOpen?: boolean;
};

export type BillingPageState = {
  path: 'billing';
  billingTooltipOpen?: boolean;
};

export type DayOfWeek = 'M' | 'T' | 'W' | 'Th' | 'F' | 'Sa' | 'Su';

export type Availability = {
  days: DayOfWeek[] | 'any';
  preferredTimes:
    | Partial<Record<DayOfWeek, (keyof typeof timeSlots)[]>>
    | 'any';
};

export interface IdentityFilters {
  ethnicity?: string;
  gender?: string;
  language?: string;
}

export type AvailabilityParam = Record<
  DayOfWeek,
  (typeof timeSlots)[keyof typeof timeSlots][]
>;

export type CareTeamPageState = {
  path: 'care-team';
  root: {
    licenseTooltipOpen?: boolean;
    practiceTooltipOpen?: boolean;
    activeTooltipNpi?: number | null;
  };
  providerProfile: {
    licenseTooltipOpen?: boolean;
    practiceTooltipOpen?: boolean;
  };
  rematch: {
    availability?: Availability;
    availabilityPickerOpen?: boolean;
    identityFilters?: IdentityFilters;
    specializationFilter?: string;
    licenseTooltipOpen?: boolean;
    practiceTooltipOpen?: boolean;
    selectedProvider?: ProviderDetail;
    providerToReplace?: number;
    rematchProviders: ServerData<{ rows: ProviderDetail[] }>;
    modalityFilter?: string;
  };
};

export type AppointmentsPageState = {
  path: 'appointments';
};

export type MessagingPageState = {
  path: 'messages';
};

// need a default for pages that don't have any state yet
export type NullPageState = {
  path: '';
};

export type PageState =
  | ProfilePageState
  | BillingPageState
  | CareTeamPageState
  | AppointmentsPageState
  | MessagingPageState
  | NullPageState;

export type PagePaths = PageState['path'];

export type RelationshipAccounts = ActiveOrExpiredRelationshipAccount[];
export type AccessType =
  | 'parent_guardian'
  | 'self'
  | 'medical_guardian'
  | 'minor_13_17';
export interface ActiveRelationshipAccount {
  access_type: AccessType;
  acting_agent_uuid: string;
  patient_first_name: string;
  patient_last_name: string;
  patient_preferred_name: string;
  patient_record_uuid: string;
  advanced_md_office_key: number;
  salesforce_contact_id: string;
  expired: false;
  patient_uuid: string;
}

export interface ExpiredRelationshipAccount {
  patient_first_name: string;
  patient_last_name: string;
  patient_preferred_name: string;
  patient_record_uuid: string;
  expired: true;
  patient_uuid?: string;
}

export type ActiveOrExpiredRelationshipAccount =
  | ActiveRelationshipAccount
  | ExpiredRelationshipAccount;

export type SelectedAccount = Pick<
  ActiveRelationshipAccount,
  | 'patient_record_uuid'
  | 'patient_uuid'
  | 'advanced_md_office_key'
  | 'salesforce_contact_id'
  | 'acting_agent_uuid'
  | 'access_type'
>;
// information about user session
// intermediate authed state between verification and account selection that has selectedAccount and accounts undefined
export interface Session {
  email: string;
  expires: number;
  accountSelected?: SelectedAccount;
  accounts?: RelationshipAccounts;
}
export interface AuthenticatedState extends State {
  session: Session;
  // data from the server
  data: {
    [K in keyof Data]: ServerData<Data[K]>;
  };
  ui: {
    usedAvailabilitySlots: Record<number, number[]>;
    userMenuOpen?: boolean;
    hamburgerMenuOpen?: boolean;
    supportInfoOpen?: boolean;
    page: PageState;
    modal: ModalProps | null;
    banner: BannerProps | null;
  };
}

type CareTeamPageRoutes = 'root' | 'providerProfile' | 'rematch';
export interface ToolTipActionPayload {
  page: CareTeamPageRoutes;
  open: boolean;
  activeTooltipNpi?: number | null;
}

export type WindowStorage = 'localStorage' | 'sessionStorage';

// analytics

export type ProductArea =
  | 'AddCareTeamModal'
  | 'Appointments'
  | 'AppointmentSelectionPage'
  | 'CareTeam'
  | 'Billing'
  | 'BookingModal'
  | 'Profile'
  | 'ProviderResults'
  | 'Rematch'
  | 'Rematch24HourCancellationWarningModal'
  | 'RematchConfirmationPage'
  | 'RematchPage'
  | 'RematchProviderDetails'
  | 'RematchProviderResults'
  | 'AddCareTeamConfirmationPage'
  | 'AddCareTeamProviderDetails'
  | 'AddCareTeamProviderResults'
  | 'Reschedule24HourCancellationWarningModal'
  | 'RescheduleAppointmentConfirmationPage'
  | 'SignIn'
  | 'Verification'
  | 'Messages';

export type Trigger =
  | 'Deeplink'
  | 'Page load'
  | 'Interaction'
  | 'Automation'
  | 'Results loaded';

export interface AnalyticsEvent {
  product_area: ProductArea;
  name: string;
  trigger: Trigger;
  metadata?: { [key: string]: JSONValue };
}

export interface AppointmentTimeInfo {
  appointmentDayMonthDate: string;
  appointmentTimeZone: string;
  appointmentDayAbbr: string;
  appointmentDate: string;
  appointmentStartTime: string;
  appointmentEndTime?: string;
}

export interface Messaging {
  threads: MessageThreads;
}

export interface MessageThreadData {
  uuid: string;
  patientUuid: string;
  providerUuid: string;
  unreadCount: number;
  lastSent: string;
}

export type MessagingParticipantType =
  | 'provider'
  | 'patient'
  | 'parent_guardian';

export interface Participant {
  participantType: MessagingParticipantType;
  participantId: string;
}
export interface Author {
  id: string;
  type: MessagingParticipantType;
}
export interface Message {
  author: Author;
  createdAt: string;
  messageText: string;
}
export interface ThreadDetail {
  messageThreadUuid: string;
  patientUuid: string;
  providerUuid: string;
  participants: Participant[];
  messages: Message[];
  hasMore: boolean;
  nextEndTimestamp: string;
}

export type SurveyKeys =
  | 'AUDIT-C'
  | 'AUDITC'
  | 'CAGE-AID'
  | 'CAGEAID'
  | 'C-SSRS'
  | 'CSSRS'
  | 'GAD-7'
  | 'GAD7'
  | 'PHQ-9'
  | 'PHQ9'
  | 'PHQ9A'
  | 'PHQ-9A'
  | 'PHQ9-A'
  | 'TA'
  | 'TA_historical';
export interface MeasureMetadata {
  uuid: string;
  measure_type: SurveyKeys;
  score: number | string;
  created_at: string;
  responder: 'patient';
  responded_at: string;
}

// note uuid and survey_bundle_uuid are the exact same here
export type Survey = {
  uuid?: string;
  patient_uuid: string;
  appointment_uuid: string;
  survey_care_period_uuid: string | null;
  completed_at: string | null;
  measures: MeasureMetadata[];
};
