import React, { useEffect } from 'react';
import { Dispatch } from 'react';
import RulaLogo from './components/assets/RulaLogo';
import Login from './login/Login';
import MagicLinkExpired from './login/MagicLinkExpired';
import MagicLinkUsed from './login/MagicLinkUsed';
import SessionExpired from './login/SessionExpired';
import VerifyDob from './login/VerifyDob';
import { Action } from './state/actions';
import { defaultState } from './state/reducer';
import { UnauthenticatedState } from './utils/types';
import VerifyPhoneNumber from './login/VerifyPhoneNumber';
import trackEvent from './utils/amplitude';

export interface UnauthenticatedProps {
  state: UnauthenticatedState;
  dispatch: Dispatch<Action>;
}

const UnauthenticatedContext = React.createContext<{
  state: UnauthenticatedState;
  dispatch: Dispatch<Action>;
}>({
  state: defaultState,
  dispatch: () => {
    console.error('dispatch called before initialization');
  },
});

const getVerificationComponents = (
  state: UnauthenticatedState,
  dispatch: Dispatch<Action>,
) => {
  if (state.ui.status === 'unverified_phone') {
    return (
      <VerifyPhoneNumber
        state={state.ui}
        dispatch={dispatch}
        token={state.token || null}
      />
    );
  }
  if (state.ui.status === 'unverified_birthdate') {
    return (
      <VerifyDob
        state={state.ui}
        dispatch={dispatch}
        token={state.token || null}
      />
    );
  }
  return null;
};

const Unauthenticated = ({ state, dispatch }: UnauthenticatedProps) => {
  useEffect(() => {
    switch (state.ui.status) {
      case 'login':
        trackEvent({
          product_area: 'SignIn',
          name: 'Login_page_viewed',
          trigger: 'Page load',
        });
        break;
      case 'unverified_birthdate':
      case 'unverified_phone':
        trackEvent({
          product_area: 'Verification',
          name: 'Verification_page_viewed',
          trigger: 'Interaction',
        });
        break;
      default:
        break;
    }
  }, [state.ui.status]);
  return (
    <UnauthenticatedContext.Provider value={{ state, dispatch }}>
      <div className="overflow-hidden h-screen relative">
        <div className="flex md:justify-start justify-center mt-5 md:mt-8 md:ml-8">
          <RulaLogo />
        </div>
        <div className="flex w-full h-full flex-col items-center">
          <div className="flex md:min-w-[33%] flex-col px-12 justify-center align-center relative mt-16 md:mt-[160px]">
            {state.ui.status === 'login' && (
              <Login state={state.ui} dispatch={dispatch} />
            )}

            {getVerificationComponents(state, dispatch)}

            {state.ui.status === 'magic_link_expired' && (
              <MagicLinkExpired dispatch={dispatch} />
            )}
            {state.ui.status === 'magic_link_used' && (
              <MagicLinkUsed dispatch={dispatch} />
            )}
            {state.ui.status === 'session_expired' && (
              <SessionExpired dispatch={dispatch} />
            )}
          </div>
        </div>
      </div>
    </UnauthenticatedContext.Provider>
  );
};

export default Unauthenticated;
