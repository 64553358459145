import Phone from '@/components/icons/Phone';
import User from '@/components/icons/User';
import TextInput from '@/components/TextInput';
import Text from '@/components/Text';
import actions from '@/state/actions';
import {
  AuthenticatedState,
  ExtractEditing,
  isEditingEmergencyContact,
  PatientData,
  ProfilePageState,
} from '@/utils/types';
import EditSection from './EditSection';
import ReadSection from './ReadSection';
import { useContext, useId } from 'react';
import { StateContext } from '@/App';
import fetch from '@/utils/fetch';
import useValidation, {
  makeErrorState,
  ValidationError,
} from '@/utils/use-validation';
import { capitalizeName, phone as formatPhone } from '@/utils/format';
import trackEvent from '@/utils/amplitude';

export interface ReadProps {
  emergencyContactName?: string;
  emergencyContactPhone?: string;
}

export interface EmergencyContactDetailsProps {
  details: Extract<
    AuthenticatedState['data']['profile'],
    { loadingState: 'done' }
  >;
  patientData: PatientData;
  pageState: ProfilePageState;
}

const Read = ({
  emergencyContactName = '',
  emergencyContactPhone = '',
}: ReadProps) => {
  const { dispatch } = useContext(StateContext);

  return (
    <ReadSection
      label="Emergency contact details"
      onEditClick={() =>
        dispatch(actions.profile.setDetailsEditMode('edit', true))
      }
    >
      <ReadSection.Row
        Icon={User}
        data={capitalizeName(emergencyContactName || '')}
      />
      <ReadSection.Row Icon={Phone} data={formatPhone(emergencyContactPhone)} />
    </ReadSection>
  );
};

const Edit = ({
  editState,
  patientData,
}: ExtractEditing<EmergencyContactDetailsProps['details']> & {
  patientData: PatientData;
  pageState: ProfilePageState;
}) => {
  const { dispatch } = useContext(StateContext);

  const { edited } = editState;
  const { emergency_contact_name = '', emergency_contact_phone = '' } = edited;
  const { invalid, persistence } = editState;
  const rawEmergencyContactPhone = emergency_contact_phone
    .match(/\d/g)
    ?.join('');

  const validation = useValidation(() =>
    dispatch(actions.profile.setInvalid({ key: 'profile', invalid: false })),
  )(() => {
    const errors: ValidationError[] = [];
    if (!rawEmergencyContactPhone || rawEmergencyContactPhone.length !== 10) {
      errors.push({
        keys: ['emergency_contact_phone'],
        message: 'Please provide a valid emergency contact phone number',
      });
    }
    if (!emergency_contact_name) {
      errors.push({
        keys: ['emergency_contact_name'],
        message: 'Please provide an emergency contact name',
      });
    }
    return errors;
  }, [emergency_contact_name, emergency_contact_phone]);

  const saveProfileDetails = () => {
    () => dispatch(actions.setToast(null)); // clear any existing toasts
    if (validation.length) {
      dispatch(actions.profile.setInvalid({ key: 'profile', invalid: true }));
      return false;
    }
    dispatch(actions.profile.setDetailsPersistence('saving'));
    return fetch
      .json('/api/update_patient_details', {
        method: 'PUT',
        body: {
          patientIdentifiers: {
            patient_uuid: patientData.patient_uuid,
          },
          payload: {
            emergency_contact_name: emergency_contact_name,
            emergency_contact_phone: rawEmergencyContactPhone!,
          },
        },
      })
      .then(() => {
        trackEvent({
          product_area: 'Profile',
          name: 'patient_emergency_details_updated',
          trigger: 'Interaction',
        });
        dispatch(actions.profile.setDetailsPersisted());
      })
      .catch(() => {
        dispatch(
          actions.setToast({
            text: (
              <Text.P>
                We're sorry, but that didn't work. Please try again.
              </Text.P>
            ),
            variant: 'warning',
            onClose: () => dispatch(actions.setToast(null)),
          }),
        );
        dispatch(actions.profile.setDetailsPersistence('error'));
        return Promise.reject();
      });
  };

  const errorState = makeErrorState(!!invalid, validation);
  const ecPhoneInputId = `input-${useId()}`;
  const ecNameInputId = `input-${useId()}`;

  return (
    <EditSection
      validationErrors={validation.map(({ message }) => message)}
      invalid={!!invalid}
      onSave={saveProfileDetails}
      onCancel={() => dispatch(actions.profile.setDetailsEditMode('read'))}
      label="Personal details"
      saving={persistence === 'saving'}
    >
      <div className="flex flex-wrap md:gap-x-2 gap-y-4">
        <div className="basis-full space-y-1">
          <Text.P>Emergency Contact Phone</Text.P>
          <label className="sr-only" htmlFor={ecPhoneInputId}>
            Emergency Contact Phone
          </label>
          <TextInput
            value={formatPhone(emergency_contact_phone)}
            state={errorState('emergency_contact_phone')}
            format={formatPhone}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetail({
                  emergency_contact_phone: v,
                }),
              )
            }
            id={ecPhoneInputId}
          />
        </div>
        <div className="basis-full space-y-1">
          <Text.P>Emergency Contact Name</Text.P>
          <label className="sr-only" htmlFor={ecNameInputId}>
            Emergency Contact Name
          </label>
          <TextInput
            value={emergency_contact_name}
            state={errorState('emergency_contact_name')}
            onChange={(v) =>
              dispatch(
                actions.profile.setEditedProfileDetail({
                  emergency_contact_name: v,
                }),
              )
            }
            id={ecNameInputId}
          />
        </div>
      </div>
    </EditSection>
  );
};

const EmergencyContactDetails = ({
  details,
  patientData,
  pageState,
}: EmergencyContactDetailsProps) => {
  return isEditingEmergencyContact(details) ? (
    <Edit {...details} patientData={patientData} pageState={pageState} />
  ) : (
    <Read
      emergencyContactName={details.emergency_contact_name}
      emergencyContactPhone={details.emergency_contact_phone}
    />
  );
};

export default EmergencyContactDetails;
