import { useState, useContext } from 'react';
import Button from '@/components/Button';
import Ellipsis from '@/components/icons/Ellipsis';
import ReplaceProviderButtonV2 from './ReplaceProviderButtonV2';
import { ProviderWithFirstAppointmentInfo, TherapyType } from '@/utils/types';
import { useNavigate } from 'react-router-dom';
import { MobileContext } from '@/App';
import Text from '@/components/Text';
import trackEvent from '@/utils/amplitude';
import { useFlagCheck } from '@/utils/use-feature-flags';
import useData from '@/state/use-data';

interface Props {
  provider: ProviderWithFirstAppointmentInfo;
  therapyType: TherapyType;
  active: boolean;
  canBook?: boolean;
}

const ProviderCardActions = ({
  provider,
  therapyType,
  active,
  canBook = false,
}: Props) => {
  const mobile = useContext(MobileContext);
  const { WithData, data } = useData(['profile']);
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isAllowed } = useFlagCheck();
  const showBookAppointments = isAllowed({
    patientPortalReleaseBookFollowupAppointments: true,
  });

  const handleBookClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    trackEvent({
      product_area: 'CareTeam',
      name: 'book_appointment_clicked',
      trigger: 'Interaction',
    });
    navigate(
      `/appointments/schedule-follow-up/${provider.npi}/${therapyType}${
        !active ? '?reactivate=true' : ''
      }`,
    );
  };

  if (!active) {
    if (provider.status !== 'live') {
      return (
        <div className="items-center md:flex px-3">No longer with Rula</div>
      );
    }
    return (
      <WithData data={data}>
        {({ profile }) => {
          const { location } = profile;
          if (provider.two_letter_state === location) {
            return (
              <Button
                size={mobile ? 'small' : 'medium'}
                variant="primary-outline"
                onClick={(e: React.MouseEvent) => handleBookClick(e)}
              >
                {mobile ? 'Book' : 'Book appointment'}
              </Button>
            );
          } else {
            return (
              <div className="items-center md:flex px-3">
                No longer in your state
              </div>
            );
          }
        }}
      </WithData>
    );
  }

  return (
    <div
      className="relative"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setMobileMenuOpen(!mobileMenuOpen);
      }}
    >
      <Button
        size="small"
        data-testid="overflow-menu"
        customClasses={mobile ? 'items-center min-h-[42px]' : undefined}
      >
        <Ellipsis />
      </Button>
      {mobileMenuOpen ? (
        <div
          className={`absolute flex flex-col cursor-pointer gap-6 p-4 top-11 w-[188px] bg-tertiary-0 z-10 rounded-2 shadow-hover right-0 ${
            mobile ? 'right-0' : 'left-0'
          }`}
        >
          {showBookAppointments && canBook ? (
            <Text.P
              role="button"
              onClick={(e: React.MouseEvent) => handleBookClick(e)}
            >
              Book appointment
            </Text.P>
          ) : null}
          <ReplaceProviderButtonV2
            providerNpi={provider.npi}
            therapyType={therapyType}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ProviderCardActions;
