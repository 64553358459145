import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import Text from '@/components/Text';
import {
  providerLicenseDescriptions,
  providerLicenses,
  therapyTypeHeader,
} from '@/utils/constants';
import InfoCircle from '@/components/icons/InfoCircle';
import stopPropagation from '@/utils/stop-propagation';
import actions, { Action } from '@/state/actions';
import InfoCard from '@/components/InfoCard';
import { CareTeamPageState, ProviderDetail, TherapyType } from '@/utils/types';
import { useContext } from 'react';
import { MobileContext } from '@/App';
import CheckboxPill from '@/components/Pills/CheckboxPill';

interface Props {
  therapyType?: TherapyType;
  dispatch: (a: Action) => unknown;
  pageState: CareTeamPageState;
  provider: ProviderDetail;
  isProfile?: boolean | null;
}

export default function ProviderHeader({
  therapyType,
  dispatch,
  pageState,
  provider,
  isProfile,
}: Props) {
  const mobile = useContext(MobileContext);

  return (
    <div className="md:flex relative justify-center md:justify-start items-end gap-x-6 text-left">
      <ProviderHeadshot
        profileImage={provider.profile_image_s3_url}
        className="block w-44 h-44 mx-auto"
        verticalAlignment="top"
      />
      <div className="w-full self-center">
        <div className="mt-6 md:mt-0 mb-3 text-xl text-center md:text-left relative">
          <Text.H2 className="text-tertiary-7 pb-[4px] md:text-3xl">
            {provider.first_name} {provider.last_name}
          </Text.H2>
          <Text.P className="text-ellipsis overflow-hidden whitespace-nowrap md:whitespace-normal mx-auto md:mx-[unset]">
            {providerLicenses[provider.licenses[0]]?.name && (
              <InfoCircle.Small
                className="inline align-middle"
                onClick={stopPropagation(() =>
                  dispatch(
                    actions.careTeam.setLicenseTooltipOpen({
                      open: !pageState.providerProfile.licenseTooltipOpen,
                      page: 'providerProfile',
                    }),
                  ),
                )}
                onMouseEnter={
                  mobile
                    ? undefined
                    : () =>
                        dispatch(
                          actions.careTeam.setLicenseTooltipOpen({
                            open: true,
                            page: 'providerProfile',
                          }),
                        )
                }
              />
            )}
            {providerLicenses[provider.licenses[0]]?.name || ''}
          </Text.P>
          {pageState.providerProfile.licenseTooltipOpen && (
            <div
              onMouseLeave={
                mobile
                  ? undefined
                  : () =>
                      dispatch(
                        actions.careTeam.setLicenseTooltipOpen({
                          open: false,
                          page: 'providerProfile',
                        }),
                      )
              }
              className="flex-grow z-10 absolute translate-y-[calc(-100%_-_2rem)] md:translate-y-0 md:top-0 left-[0] md:left-0 md:w-max max-w-prose mb-2 md:mb-0 items-center"
            >
              <InfoCard>
                <Text.P>
                  {
                    providerLicenseDescriptions[
                      provider.licenses[0].toUpperCase()
                    ]?.description
                  }
                </Text.P>
              </InfoCard>
            </div>
          )}
        </div>
        {isProfile ? null : therapyType ? (
          <CheckboxPill>{therapyTypeHeader[therapyType]}</CheckboxPill>
        ) : null}
      </div>
    </div>
  );
}
