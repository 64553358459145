import { AnalyticsEvent, ActiveRelationshipAccount, Session } from './types';
import * as amplitude from '@amplitude/analytics-browser';

const trackEvent = ({
  product_area,
  trigger,
  metadata,
  name,
}: AnalyticsEvent) => {
  const event_type = 'PATIENT_APP' + '_' + product_area + '_' + name;

  const eventData = {
    event_type: event_type,
    event_properties: Object.assign(
      {
        system_name: 'Patient Portal Frontend',
        trigger,
      },
      metadata,
    ),
  };

  void amplitude.track(eventData);
};

const getUserType = (activeAccounts: ActiveRelationshipAccount[]) => {
  let user_type = 'PatientAdult';
  if (!activeAccounts) {
    return user_type;
  }

  if (activeAccounts?.length === 1) {
    switch (activeAccounts[0].access_type) {
      case 'minor_13_17':
        user_type = 'PatientMinor';
        break;
      case 'medical_guardian':
        user_type = 'PatientMedicalGuardian';
        break;
      case 'parent_guardian':
        user_type = 'PatientParent';
        break;
      default:
        break;
    }
  } else {
    user_type = activeAccounts?.some(
      (account) => account.access_type === 'self',
    )
      ? 'PatientAdultParent'
      : 'PatientParent';
  }
  return user_type;
};

export const identifyAmplitudeUser = (session: Session) => {
  // get user_type from account access_type
  const activeAccounts = (session.accounts?.filter(({ expired }) => !expired) ||
    []) as ActiveRelationshipAccount[];
  const user_type = getUserType(activeAccounts);

  // get identifiers
  const patient_uuid = session.accountSelected?.patient_uuid || '';
  const salesforce_contact_id =
    session.accountSelected?.salesforce_contact_id || '';

  //identify
  const identity = new amplitude.Identify()
    .set('patient_uuid', patient_uuid)
    .set('salesforce_contact_id', salesforce_contact_id)
    .set('user_type', user_type);
  amplitude.identify(identity);
  amplitude.setUserId(patient_uuid);
};

export default trackEvent;
