import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import {
  GroupedSlotsForDay,
  SchedulingDay,
  AppointmentV2,
  AppointmentTimeInfo,
} from './types';

dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const monthOrder = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const isMmDdYyyy = (date: string) =>
  date.match(/\d{1,2}\/\d{1,2}\/\d{4}/);

export const parseMmDdYyyy = (date: string) => {
  if (!isMmDdYyyy(date)) {
    throw `${date} is not a valid MM/DD/YYYY date`;
  }
  return dayjs(
    date,
    ['MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY'],
    true,
  );
};

export const getSlotsByDay = (numericSlots: number[]) => {
  // The allowed time range for scheduling is 25 hours from now to 4 weeks from now
  const startDay = dayjs().add(25, 'hours');
  const schedulingDays: Map<number, SchedulingDay> = new Map<
    number,
    SchedulingDay
  >();
  [...Array(28).keys()].map((offset) => {
    const schedulingDate = startDay.add(offset, 'days');
    const schedulingDay: SchedulingDay = {
      date: schedulingDate,
      slots: [],
      index: offset,
    };
    schedulingDays.set(schedulingDate.date(), schedulingDay);
  });

  // Put each slot on the appropriate day
  numericSlots.forEach((slot) => {
    const slotDate = dayjs.unix(slot);
    const schedulingDay = schedulingDays.get(slotDate.date());
    if (schedulingDay) {
      schedulingDay.slots?.push(slotDate);
      schedulingDays.set(slotDate.date(), schedulingDay);
    }
  });

  schedulingDays.forEach((value) => {
    if (value.slots) {
      value.GroupedSlotsForDay = groupSlotsOnDay(value.slots);
    }
  });

  return schedulingDays;
};

export const groupSlotsOnDay = (slotsThisDay: Dayjs[]) => {
  const groupedSlots: GroupedSlotsForDay = {
    Morning: [],
    Afternoon: [],
    Evening: [],
  };
  slotsThisDay.map((slot) => {
    if (slot.hour() < 12) {
      groupedSlots.Morning.push(slot);
    } else if (slot.hour() < 16) {
      groupedSlots.Afternoon.push(slot);
    } else {
      groupedSlots.Evening.push(slot);
    }
  });
  return groupedSlots;
};

export const isInCurrentBusinessWeekAndFuture = (date: Dayjs): boolean => {
  const now = dayjs();
  const inSameWeekAndInFuture = date.week() === now.week() && date.isAfter(now);

  if (inSameWeekAndInFuture) {
    const dayOfWeek = date.day();
    // M - F
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      return true;
    }
  }

  return false;
};

export const getNextActiveAppointmentV2 = (
  appointments: AppointmentV2[],
  providerNpi?: number,
): AppointmentV2 | undefined => {
  if (!providerNpi) return undefined;
  return appointments.find(
    ({ status, npi, start_time }) =>
      dayjs(start_time).isAfter(dayjs()) &&
      status !== 'canceled' &&
      status !== 'deleted' &&
      providerNpi === npi,
  );
};

export const getAppointmentTimeZone = (dateObj: Dayjs): string => {
  return dateObj.format('z');
};
export const getAppointmentDayAbbr = (dateObj: Dayjs): string => {
  return dateObj.format('ddd');
};
export const getAppointmentDate = (dateObj: Dayjs): string => {
  return dateObj.format('D');
};
export const getAppointmentDayMonthDate = (dateObj: Dayjs): string => {
  return dateObj.format('dddd, MMMM Do');
};
export const getAppointmentTime = (
  dateObj: Dayjs,
  withTimeZone?: boolean,
): string => {
  const formatShape = withTimeZone ? 'h:mma z' : 'h:mma';
  return dateObj.format(formatShape);
};

export const getAppointmentTimeInfo = (
  timeString: string,
  endTimeString?: string,
): AppointmentTimeInfo => {
  const dateObj = dayjs(timeString, { utc: true }).local();
  const appointmentTimeInfo: AppointmentTimeInfo = {
    appointmentDayMonthDate: getAppointmentDayMonthDate(dateObj),
    appointmentTimeZone: getAppointmentTimeZone(dateObj),
    appointmentDayAbbr: getAppointmentDayAbbr(dateObj),
    appointmentDate: getAppointmentDate(dateObj),
    appointmentStartTime: getAppointmentTime(dateObj),
  };
  if (endTimeString) {
    const endDateObj = dayjs(endTimeString, { utc: true }).local();
    appointmentTimeInfo.appointmentEndTime = getAppointmentTime(endDateObj);
  }
  return appointmentTimeInfo;
};
export const groupAppointmentsByMonthAndYear = (
  appointments: AppointmentV2[],
): { [monthYear: string]: AppointmentV2[] } => {
  const appointmentMap: { [monthYear: string]: AppointmentV2[] } = {};
  if (appointments.length === 0) return appointmentMap;

  appointments.forEach((appointment) => {
    const dateObj = dayjs(appointment.start_time, { utc: true }).local();
    const monthYear = dateObj.format('MMMM YYYY');

    if (appointment.status !== 'canceled' && appointment.status !== 'deleted') {
      if (!appointmentMap[monthYear]) {
        appointmentMap[monthYear] = [];
      }
      appointmentMap[monthYear].push(appointment);
    }
  });

  const sortedMonths = Object.keys(appointmentMap).sort((a, b) => {
    const [monthA, yearA] = a.split(' ');
    const [monthB, yearB] = b.split(' ');

    // Compare years first
    if (yearA !== yearB) {
      return yearA < yearB ? -1 : 1;
    }

    // If years are equal, compare months
    return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
  });

  const sortedAppointmentMap: { [monthYear: string]: AppointmentV2[] } = {};
  sortedMonths.forEach((monthYear) => {
    sortedAppointmentMap[monthYear] = appointmentMap[monthYear];
  });

  return sortedAppointmentMap;
};

export const appointmentIsPast = (appointmentUTC: string) => {
  const appointmentDay = dayjs(appointmentUTC);
  const now = dayjs();
  return appointmentDay.isBefore(now);
};
export const appointmentIsWithin24Hours = (appointmentUTC: string) => {
  const appointmentDay = dayjs(appointmentUTC);
  const now = dayjs();
  return (
    appointmentDay.isAfter(now) &&
    appointmentIsBefore24HoursFromNow(appointmentUTC)
  );
};
export const appointmentIsBefore24HoursFromNow = (appointmentUTC: string) => {
  const appointmentDay = dayjs(appointmentUTC);
  const overOneDayFromNow = dayjs().add(1, 'day').add(1, 'minute');
  return appointmentDay.isBefore(overOneDayFromNow);
};

export const getAppointmentLengthAndEndTime = (
  startTime: string,
  endTime: string,
  newAppointmentUnix?: number,
): { appointmentLengthInMinutes: number; newEndTime: Dayjs | undefined } => {
  const appointmentLength = dayjs(endTime).diff(dayjs(startTime));
  const appointmentLengthInMinutes = Math.floor(appointmentLength / 60000);
  let newEndTime;
  if (newAppointmentUnix) {
    newEndTime = getAppointmentEndTime(
      newAppointmentUnix,
      appointmentLengthInMinutes,
    );
  }

  return { appointmentLengthInMinutes, newEndTime };
};

export const getAppointmentEndTime = (
  startTimeUnix: number,
  durationMinutes: number,
): Dayjs => {
  const startTime = dayjs.unix(startTimeUnix);
  const durationMillis = durationMinutes * 60000;
  return startTime.add(durationMillis);
};

export const getSlotsInNextSevenDays = (bookableSlots: number[]) => {
  const slotsByDay = getSlotsByDay(bookableSlots);
  const days: Dayjs[] = [];
  slotsByDay.forEach((value) => {
    days.push(value.date);
  });
  let countInNextSevenDays = 0;
  for (let i = 0; i < 7; i++) {
    const numberOfSlots = slotsByDay.get(days[i].date())?.slots?.length || 0;
    countInNextSevenDays += numberOfSlots;
  }
  return countInNextSevenDays;
};

export const getAgeFromBirthdate = (birthdate: string) => {
  return dayjs().diff(dayjs(birthdate), 'year');
};
