import Text from '@/components/Text';
import RadioButton from '@/components/RadioButton';
import { makeCorrectCasing } from '@/utils/format';
import { IdentityFilters, ProviderOptions } from '@/utils/types';
import { useState } from 'react';
import ModalWrapper from '@/components/ModalWrapper';

interface IdentityModalProps {
  onUpdate: (updatedFilters: IdentityFilters) => unknown;
  providerOptions: ProviderOptions;
  selected?: IdentityFilters;
}

export default function IdentityModal({
  onUpdate,
  providerOptions,
  selected,
}: IdentityModalProps) {
  const [selectedEthnicity, setSelectedEthnicity] = useState(
    selected?.ethnicity ?? '',
  );
  const [selectedGender, setSelectedGender] = useState(selected?.gender ?? '');
  const [selectedLanguage, setSelectedLanguage] = useState(
    selected?.language ?? '',
  );
  const sortedEthnicities = [...(providerOptions.races ?? [])].sort();
  const sortedGenders = [...(providerOptions.genders ?? [])].sort();
  const sortedLanguages = [...(providerOptions.languages ?? [])].sort();

  return (
    <ModalWrapper
      heading="Identity"
      onSave={(event) => {
        event?.preventDefault();
        onUpdate({
          ethnicity: selectedEthnicity,
          gender: selectedGender,
          language: selectedLanguage,
        });
      }}
    >
      <>
        <div>
          <Text.P.Bold className="mb-3">Gender</Text.P.Bold>
          <div className="max-h-[465px] columns-2 space-y-2 mb-5">
            <RadioButton
              label="Any gender"
              onCheck={() => setSelectedGender('')}
              name="gender"
              selectedValue={selectedGender}
              value={''}
            />
            {sortedGenders.map((gender) => (
              <RadioButton
                key={gender}
                label={makeCorrectCasing(gender, true)}
                onCheck={() => setSelectedGender(gender)}
                name="gender"
                selectedValue={selectedGender}
                value={gender}
              />
            ))}
          </div>
        </div>
        <div className="border-b-1 mt-5 mb-5 border-tertiary-2"></div>
        <div>
          <Text.P.Bold className="mb-3">Ethnicity</Text.P.Bold>
          <div className="max-h-[465px] columns-2 space-y-2 mb-5">
            <RadioButton
              label="Any ethnicity"
              onCheck={() => setSelectedEthnicity('')}
              name="ethnicity"
              selectedValue={selectedEthnicity}
              value={''}
            />
            {sortedEthnicities.map((ethnicity) => (
              <RadioButton
                key={ethnicity}
                label={makeCorrectCasing(ethnicity, true)}
                onCheck={() => setSelectedEthnicity(ethnicity)}
                name="ethnicity"
                selectedValue={selectedEthnicity}
                value={ethnicity}
              />
            ))}
          </div>
        </div>
        <div className="border-b-1 mt-5 mb-5 border-tertiary-2"></div>
        <div>
          <Text.P.Bold className="mb-3">Language</Text.P.Bold>
          <div className="columns-2 space-y-2 mb-5">
            <RadioButton
              label="Any language"
              onCheck={() => setSelectedLanguage('')}
              name="language"
              selectedValue={selectedLanguage}
              value={''}
            />
            {sortedLanguages.map((language) => (
              <RadioButton
                key={language}
                label={makeCorrectCasing(language, true)}
                onCheck={() => setSelectedLanguage(language)}
                name="language"
                selectedValue={selectedLanguage}
                value={language}
              />
            ))}
          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
