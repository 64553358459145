import Icon, { IconProps } from './Icon';

const Insurance = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M9 1.53503V20.535M17 11.035C17 15.9435 11.646 19.5134 9.69799 20.6499C9.4766 20.7791 9.3659 20.8436 9.20968 20.8771C9.08844 20.9031 8.91156 20.9031 8.79032 20.8771C8.6341 20.8436 8.5234 20.7791 8.30201 20.6499C6.35396 19.5134 1 15.9435 1 11.035V6.25263C1 5.45312 1 5.05336 1.13076 4.70973C1.24627 4.40617 1.43398 4.1353 1.67766 3.92056C1.9535 3.67747 2.3278 3.5371 3.0764 3.25638L8.4382 1.2457C8.6461 1.16774 8.75005 1.12876 8.85698 1.11331C8.95184 1.0996 9.04816 1.0996 9.14302 1.11331C9.24995 1.12876 9.3539 1.16774 9.5618 1.2457L14.9236 3.25638C15.6722 3.5371 16.0465 3.67747 16.3223 3.92056C16.566 4.1353 16.7537 4.40617 16.8692 4.70973C17 5.05336 17 5.45312 17 6.25263V11.035Z"
        stroke="#262626"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Icon>
  );
};

export default Insurance;
