import { Dispatch } from 'react';
import actions, { Action } from '@/state/actions';
import { Data } from './types';
import fetch from './fetch';

const switchAccount = async (dispatch: Dispatch<Action>) => {
  await fetch('/api/selected_account', { method: 'DELETE' });
  dispatch(actions.setAccountSelected());

  const neededData: (keyof Data)[] = [
    'patientData',
    'appointments_v2',
    'careTeam_v3',
    'billing',
  ];
  neededData.forEach((key) => {
    dispatch(
      actions.async.setLoading({
        key,
        loadingState: 'needed',
      }),
    );
  });
};

export default switchAccount;
