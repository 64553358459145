import { Data, ServerData } from '../../utils/types';

export default {
  setLoading: <K extends keyof Data>(payload: {
    key: K;
    loadingState: ServerData<unknown>['loadingState'];
  }) =>
    ({
      type: 'async/setLoading',
      payload,
    }) as const,
};
