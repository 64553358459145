import useData from '@/state/use-data';
import { ProviderProfileParams } from '@/utils/types';
import { useParams } from 'react-router-dom';
import { StateContext } from '@/App';
import { useContext } from 'react';
import RedirectToCareTeamOnErrorV2 from './RedirectToCareTeamOnErrorV2';
import BackNavigation from '@/components/BackNavigation';
import ProviderHeaderV2 from '@/pages/care-team/ProviderHeaderV2';
import ProviderDetailSection from '@/pages/care-team/ProviderDetailSection';

const ProviderProfileV2 = () => {
  const { providerNpi } = useParams<ProviderProfileParams>();
  const { dispatch, state } = useContext(StateContext);
  const pageState = state.ui.page;
  const { WithData, data } = useData(['careTeam_v3']);

  if (pageState.path !== 'care-team') {
    return null;
  }

  if (!providerNpi || Number.isNaN(Number(providerNpi))) {
    console.error('invalid provider npi');
    return <RedirectToCareTeamOnErrorV2 />;
  }

  const npi = Number(providerNpi);
  return (
    <WithData data={data}>
      {({ careTeam_v3: careTeam }) => {
        const provider = [...careTeam.active, ...careTeam.inactive].find(
          (p) => {
            return p?.npi === npi;
          },
        );
        if (!provider) {
          console.error('could not find care info for provider');
          return <RedirectToCareTeamOnErrorV2 />;
        }
        return (
          <div className="space-y-5 max-w-[715px]">
            <div className="flex">
              <BackNavigation />
            </div>
            <ProviderHeaderV2
              dispatch={dispatch}
              pageState={pageState}
              provider={provider}
              isProfile
            />

            {/* Provider profile page body */}
            <div className="lg:flex relative justify-start items-start gap-x-6 text-left">
              {/* Provider profile details */}
              <div className="w-full">
                <ProviderDetailSection provider={provider} />
              </div>
            </div>
          </div>
        );
      }}
    </WithData>
  );
};

export default ProviderProfileV2;
