import {
  Loaded,
  Billing as BillingData,
  BillingStatementRow,
  ProviderDetail,
  SelfPayRates,
} from './types';
import { makeDollars } from './format';
import { psychiatryProviderRole } from './constants';

export const groupBillingStatements = (billing: Loaded<BillingData>) => {
  return billing.statements.reduce(
    (o, s) => {
      const provider = s.statement.provider_name
        ?.replace(/\s?\(\w*\)/, '')
        .replace(',', '')
        .split(' ')
        .reverse()
        .map((n) => n[0] + n.slice(1).toLowerCase())
        .join(' ');
      if (
        o[s.statement.date_of_service] &&
        !o[s.statement.date_of_service].providers.includes(provider)
      ) {
        o[s.statement.date_of_service].providers.push(provider);
      } else {
        o[s.statement.date_of_service] = {
          providers: [provider],
          link: s.link,
        };
      }
      return o;
    },
    {} as Record<string, BillingStatementRow>,
  );
};

export const getCancellationFee = (
  provider: ProviderDetail,
  selfPayRates: Loaded<{ data: SelfPayRates }>,
) => {
  return makeDollars(
    provider.role === psychiatryProviderRole
      ? selfPayRates.data.psych_cancel
      : selfPayRates.data.therapy_cancel,
  );
};
