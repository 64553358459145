import { useContext } from 'react';
import { StateContext } from '@/App';
import WithPageState from '@/pages/WithPageState';
import actions from '@/state/actions';
import IdentityModal from '@/pages/care-team/replace-flow/IdentityModal';
import { ProviderOptions, ReactChildren } from '@/utils/types';

interface Props {
  providerOptions: ProviderOptions;
  children: (props: { onClick: () => unknown }) => ReactChildren;
}

export default function IdentityModalTrigger({
  children,
  providerOptions,
}: Props) {
  const { dispatch } = useContext(StateContext);
  const closeModal = () => dispatch(actions.setModal(null));
  return (
    <WithPageState page="care-team">
      {({ pageState }) =>
        children({
          onClick() {
            dispatch(
              actions.setModal({
                children: (
                  <IdentityModal
                    onUpdate={(updatedFilters) => {
                      dispatch(
                        actions.careTeam.setIdentityFilters(updatedFilters),
                      );
                      closeModal();
                    }}
                    providerOptions={providerOptions}
                    selected={pageState.rematch.identityFilters}
                  />
                ),
              }),
            );
          },
        })
      }
    </WithPageState>
  );
}
