import Text from '@/components/Text';
import InfoCircle from '@/components/icons/InfoCircle';

export const AddSecondaryInsuranceCard = () => (
  <div className="p-2 rounded-2 flex gap-2 items-center bg-primary-0 text-primary-4">
    <InfoCircle stroke="primary-3" className="min-w-4" />
    <div className="flex flex-col gap-1">
      <Text.Small.Bold>Adding secondary insurance</Text.Small.Bold>
      <Text.Small>
        Avoid billing disruptions by emailing{' '}
        <a href="mailto:support@rula.com">support@rula.com</a> with a copy of
        your insurance card to get started.
      </Text.Small>
    </div>
  </div>
);
