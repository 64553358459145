import { useContext, useMemo, useState } from 'react';
import Text from '@/components/Text';
import {
  Loaded,
  Billing as BillingData,
  BillingPageState,
  AnalyticsEvent,
  CreditCardDetails,
  Editable,
  EditableCreditCardDetails,
  ServerData,
} from '@/utils/types';
import useData from '@/state/use-data';
import InfoCircle from '@/components/icons/InfoCircle';
import InfoCard from '@/components/InfoCard';
import stopPropagation from '@/utils/stop-propagation';
import { MobileContext, StateContext } from '@/App';
import actions from '@/state/actions';
import StatementsTab from '@/components/StatementsTab';
import ChargesTab from '@/components/ChargesTab';
import Button from '@/components/Button';
import { Link } from 'react-router-dom';
import trackEvent from '@/utils/amplitude';
import { checkCreditCardValid } from '@/utils/use-personal-details';

export interface BillingProps {
  pageState: BillingPageState;
  billing: Loaded<BillingData>;
}

const Billing = () => {
  const { state, dispatch } = useContext(StateContext);
  const { WithData, data } = useData(['billing']);
  const mobile = useContext(MobileContext);
  const [selectedTab, setSelectedTab] = useState<string>('statements');
  const {
    creditCard,
  }: {
    creditCard: ServerData<
      Editable<CreditCardDetails, EditableCreditCardDetails>
    >;
  } = state.data;

  const creditCardIsValid = useMemo(
    () => checkCreditCardValid(creditCard),
    [creditCard],
  );

  if (state.ui.page.path !== 'billing') {
    return null;
  }

  const pageState = state.ui.page;

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (tab === 'charges') {
      const chargesViewed: AnalyticsEvent = {
        product_area: 'Billing',
        name: 'Charges_page_viewed',
        trigger: 'Interaction',
      };
      trackEvent(chargesViewed);
    }
  };

  const primaryClassName = 'text-primary-3';
  const tertiaryClassName = 'text-tertiary-5';

  const getClassName = (tabName: string) => {
    return selectedTab === tabName ? primaryClassName : tertiaryClassName;
  };

  return (
    <WithData data={data}>
      {({ billing }) => {
        const clickCardUpdateButton: AnalyticsEvent = {
          product_area: 'Billing',
          name: 'Card_update_button_click',
          trigger: 'Interaction',
        };
        return (
          <div className="w-full">
            <Text.H1 className="mb-4">Billing</Text.H1>
            <div className="p-6 border-1 border-tertiary-2 rounded w-full text-tertiary-7">
              <div className="flex items-center md:gap-x-2 gap-y-4 relative">
                <div className="flex flex-wrap flex-col w-full">
                  <div className="flex items-center gap-1">
                    <Text.Small className="text-tertiary-4">Balance</Text.Small>
                    <div className="w-5 h-7 cursor-pointer">
                      <InfoCircle.Small
                        className="inline-block"
                        onClick={stopPropagation(() =>
                          dispatch(
                            actions.billing.setBillingTooltipOpen(
                              !pageState.billingTooltipOpen,
                            ),
                          ),
                        )}
                        onMouseEnter={
                          mobile
                            ? undefined
                            : () =>
                                dispatch(
                                  actions.billing.setBillingTooltipOpen(true),
                                )
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <Text.H1 className="flex mt-0 inline-block">
                      {billing.balance}
                    </Text.H1>
                  </div>
                </div>
                {!creditCardIsValid && (
                  <Link
                    to="/profile"
                    className="no-underline"
                    onClick={() => {
                      dispatch(actions.profile.setCcEditMode('edit'));
                      trackEvent(clickCardUpdateButton);
                    }}
                  >
                    <Button
                      variant="primary"
                      size={'medium'}
                      sizeClasses="w-[175px]"
                    >
                      Update credit card
                    </Button>
                  </Link>
                )}
                {pageState.billingTooltipOpen && (
                  <div
                    className="flex-grow absolute left-0 right-0 w-full mb-2 lg:mb-0 items-center"
                    style={{
                      top: mobile ? 'calc(-100% - 30px)' : '-60px',
                      left: mobile ? '25%' : '75px',
                      width: mobile ? '70%' : '53%',
                    }}
                    onMouseLeave={
                      mobile
                        ? undefined
                        : () =>
                            dispatch(
                              actions.billing.setBillingTooltipOpen(false),
                            )
                    }
                  >
                    <InfoCard>
                      <Text.Small className="text-tertiary-6">
                        We’ll automatically charge your credit card for the full
                        balance amount.
                      </Text.Small>
                    </InfoCard>
                  </div>
                )}
              </div>
            </div>
            <div className="flex cursor-pointer mt-5">
              <div>
                <Text.P
                  className={getClassName('statements')}
                  onClick={() => handleTabClick('statements')}
                >
                  Statements
                </Text.P>
                {selectedTab === 'statements' ? (
                  <div className="h-1 bg-primary-3 w-full rounded-tr-5 rounded-br-5 rounded-bl-5 rounded-tl-5"></div>
                ) : null}
              </div>
              <div>
                <Text.P
                  className={`${getClassName('charges')}  ml-5`}
                  onClick={() => handleTabClick('charges')}
                >
                  Charges
                </Text.P>
                {selectedTab === 'charges' ? (
                  <div className="h-1 bg-primary-3 w-[calc(100%-5)] rounded-tr-5 rounded-br-5 rounded-bl-5 rounded-tl-5 ml-5"></div>
                ) : null}
              </div>
            </div>
            {selectedTab === 'statements' ? (
              <>
                <Text.H3 className="text-tertiary-7 mt-5">Statements</Text.H3>
                <StatementsTab billing={billing as Loaded<BillingData>} />
              </>
            ) : null}

            {selectedTab === 'charges' ? (
              <ChargesTab billing={billing as Loaded<BillingData>} />
            ) : (
              ''
            )}
          </div>
        );
      }}
    </WithData>
  );
};

export default Billing;
