import { useContext, useState } from 'react';
import actions from '@/state/actions';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import Text from '@/components/Text';
import {
  CarrierOption,
  Editable,
  EditableInsuranceDetails,
  InsuranceDetails,
  ProfilePageState,
} from '@/utils/types';
import { ValidationError } from '@/utils/use-validation';
import { InsuranceForm } from './InsuranceForm';
import ConfirmModal from './ConfirmModal';
import { MobileContext, StateContext } from '@/App';
import dayjs from 'dayjs';

export type AddInsuranceModalProps = {
  carriers: CarrierOption[];
  pageState: ProfilePageState;
  validation: ValidationError[];
  saveInsurance: (setting: { replaceUpcoming: boolean }) => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

export const AddInsuranceModal = ({
  carriers,
  pageState,
  validation,
  saveInsurance,
  open,
  onOpenChange,
}: AddInsuranceModalProps) => {
  const { state, dispatch } = useContext(StateContext);
  const [hidden, setHidden] = useState(false);

  const insurance = state.data.insurance as Editable<
    InsuranceDetails,
    EditableInsuranceDetails
  >;
  const upcomingInsurance = state.data.upcomingInsurance as InsuranceDetails;

  const editState = insurance.editState;
  if (editState.mode === 'read') return null;

  const closeModal = () => {
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  const showConfirmModal = ({
    title,
    description,
    onCancel,
    onConfirm,
  }: {
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    description: string;
  }) => {
    dispatch(
      actions.setModal({
        children: (
          <ConfirmModal
            dispatch={dispatch}
            onCancel={onCancel}
            onConfirm={onConfirm}
            title={title}
            description={description}
          />
        ),
      }),
    );
  };

  const onAddInsurance = () => {
    let isReplacingUpcoming = false;
    let title = '',
      description = '';

    if (validation.length) {
      dispatch(actions.profile.setInvalid({ key: 'insurance', invalid: true }));
      return;
    }

    const isEffectiveDatePast = dayjs().isSameOrAfter(
      dayjs(editState.edited.effective_date),
    );

    const formattedEffectiveDate = dayjs(
      editState.edited.effective_date,
    ).format('MM/DD/YYYY');
    const formattedCurrentInsurance = `${insurance.carrier} (${insurance.subscriber_id})`;
    const formattedNewInsurance = `${editState.edited.carrier} (${editState.edited.subscriber_id})`;
    const formattedUpcomingInsurance = `${upcomingInsurance.carrier} (${upcomingInsurance.subscriber_id})`;

    const haveCurrentInsurance = !!insurance.carrier;
    const haveUpcomingInsurance = !!upcomingInsurance.carrier;

    if (haveCurrentInsurance) {
      // insurance user
      if (isEffectiveDatePast) {
        isReplacingUpcoming = false;
        title = 'Replace your active insurance immediately?';
        description = `Your active ${formattedCurrentInsurance} plan will be replaced by your new ${formattedNewInsurance} plan immediately. `;
      } else if (haveUpcomingInsurance) {
        isReplacingUpcoming = true;
        title = 'Replace your upcoming insurance?';
        description = `Your upcoming ${formattedUpcomingInsurance} plan will be replaced by your new ${formattedNewInsurance} plan. `;
      } else {
        isReplacingUpcoming = true;
        title = `Replace your active insurance on ${formattedEffectiveDate}?`;
        description = `Your active ${formattedCurrentInsurance} plan will be replaced by your new ${formattedNewInsurance} plan on ${formattedEffectiveDate}. `;
      }
    } else {
      // cash pay user
      if (isEffectiveDatePast) {
        isReplacingUpcoming = false;
        title = 'Replace your cash payment option immediately?';
        description = `Your cash payment option will be replaced by your new ${formattedNewInsurance} plan immediately.`;
      } else {
        isReplacingUpcoming = true;
        title = `Replace your cash payment option on ${formattedEffectiveDate}?`;
        description = `Your cash payment option will be replaced by your new ${formattedNewInsurance} plan on ${formattedEffectiveDate}. `;
      }
    }

    setHidden(true);
    showConfirmModal({
      title,
      description,
      onCancel: () => {
        setHidden(false);
      },
      onConfirm: () => {
        saveInsurance({
          replaceUpcoming: isReplacingUpcoming,
        });
      },
    });
  };

  if (!open) return null;

  const mobile = useContext(MobileContext);
  const mobileTopOffset = 48 + (state.ui.banner ? 64 : 0);

  return (
    <div
      className={
        'w-screen h-screen flex justify-center items-center fixed top-0 left-0 z-20 !m-0 ' +
        (hidden ? 'hidden ' : ' ') +
        (mobile ? 'bg-transparent' : 'bg-tertiary-7 bg-opacity-50')
      }
    >
      <div
        className={
          mobile
            ? `w-screen bg-tertiary-0 flex flex-col border-1`
            : 'rounded-2 relative z-0 bg-tertiary-0  drop-shadow-lg w-[606px] max-w-[90vw] max-h-[90vh] flex flex-col'
        }
        style={
          mobile
            ? {
                height: `calc(100% - ${mobileTopOffset}px)`,
                marginTop: mobileTopOffset,
              }
            : {}
        }
      >
        <div className="flex justify-between items-center p-6">
          <Text.H4>Add insurance</Text.H4>
          <X role="img" onClick={closeModal} className="cursor-pointer"></X>
        </div>
        <div className="overflow-y-auto space-y-5 px-6 py-1">
          <Text.P className="text-tertiary-5">
            The existing insurance information on file will be replaced on the
            new plan's effective date. You will be sent an updated cost estimate
            around the time of the effective date.
          </Text.P>
          {!!editState.invalid && (
            <div className="rounded border-warning-1 border-1 w-full p-4">
              <Text.P.Bold>
                Oops! Some data you entered is not in the right format:
              </Text.P.Bold>
              <ul className="list-disc pl-4">
                {validation.map(({ message }, i) => (
                  <li key={i}>
                    <Text.P>{message}</Text.P>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <InsuranceForm
            editState={editState}
            carriers={carriers}
            pageState={pageState}
            validation={validation}
          />
        </div>
        <div
          className={
            'flex justify-end space-x-2 p-6 ' +
            (mobile ? 'border-t-1 py-4 px-6' : '')
          }
        >
          <Button
            onClick={closeModal}
            variant={mobile ? 'primary-outline' : 'flat'}
            sizeClasses={mobile ? 'flex-1' : ''}
          >
            Cancel
          </Button>
          <Button
            onClick={onAddInsurance}
            variant="primary"
            sizeClasses={mobile ? 'flex-1' : ''}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
