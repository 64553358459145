/**
 * Remove keys, the values of which are null or undefined from object
 */
export function compact<T>(
  obj: Record<string, T>,
): Record<string, NonNullable<T>> {
  const entries = Object.entries(obj);
  const valueNotNull = <A, B>(entry: [A, B]): entry is [A, NonNullable<B>] =>
    entry[1] !== null && entry[1] !== undefined;
  const filteredEntries = entries.filter(valueNotNull);
  return Object.fromEntries(filteredEntries);
}
