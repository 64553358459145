import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  isProviderDetail,
  JSONObject,
  PatientData,
  PaymentMethod,
  TherapyType,
  AppointmentV2,
  ActiveProviderShapeV3,
  JSONValue,
  WillingToSee,
} from '@/utils/types';
import { getAgeFromBirthdate } from '@/utils/dates';
import RedirectToCareTeamOnErrorV2 from '@/pages/care-team/RedirectToCareTeamOnErrorV2';
import useData from '@/state/use-data';
import { WithProvider } from '@/pages/care-team/WithProvider';
import Text from '@/components/Text';
import { ProviderLockup } from '@/pages/care-team/ProviderLockup';
import WithPageState from '@/pages/WithPageState';
import { default as CalendarIcon } from '@/components/icons/Calendar';
import dayjs from 'dayjs';
import Clock from '@/components/icons/Clock';
import Video from '@/components/icons/Video';
import { Calendar } from '@/components/assets';
import Button from '@/components/Button';
import WithProviderFromCareTeamV2 from '@/pages/care-team/WithProviderFromCareTeamV2';
import fetch from '@/utils/fetch';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { StateContext } from '@/App';
import actions, { Action } from '@/state/actions';
import SupportLink from '@/components/SupportLink';
import { ToastProps } from '@/components/Toast';
import {
  errorsCancelingHold,
  errorCreatingHold,
  errorsFindingOldAppointment,
  errorRematchSameProvider,
  errorSlotNotAvailable,
  toastSessionNotAvailable,
  toastDidntWorkTryAgain,
  toastErrorBookingSession,
  errorNotWillingToSee,
  therapyTypeHeader,
  getWillingToSeeFromTherapyType,
} from '@/utils/constants';
import ReplaceProviderCancellationPromptModal from '../ReplaceProviderCancellationPromptModal';
import FullWidthHeader from '@/components/FullWidthHeader';
import trackEvent from '@/utils/amplitude';
import { getNextActiveAppointmentV2 } from '@/utils/dates';
import { makeDollars } from '@/utils/format';

interface RematchRequestBody extends JSONObject {
  therapy_type: TherapyType;
  patient_record_uuid: string;
  old_provider_npi: number;
  new_provider_npi: number;
  slot: number;
  cancel_appointments_in_next_day: boolean;
  willing_to_see: WillingToSee;
}

const findAppointmentInNextDay = (
  appointments: AppointmentV2[],
  npi: number,
) => {
  const nextAppointment = getNextActiveAppointmentV2(appointments, npi);
  if (
    nextAppointment &&
    dayjs(nextAppointment.start_time).isBefore(dayjs().add(1, 'day'))
  ) {
    return nextAppointment;
  }
};

const onConfirm = async (args: {
  therapyType: TherapyType;
  dispatch: Dispatch<Action>;
  navigate: ReturnType<typeof useNavigate>;
  setProcessing: (processing: boolean) => unknown;
  providerName: string;
  currentCareProvider: ActiveProviderShapeV3;
  cancelApptsInNextDay?: boolean | undefined;
  patientData: PatientData;
  slot: number;
  newNpi: number;
  carrier: string | undefined;
  paymentMethod: PaymentMethod;
  cancellationFee: string;
  appointments: AppointmentV2[];
  willingToSee: WillingToSee;
}) => {
  const {
    therapyType,
    dispatch,
    navigate,
    setProcessing,
    providerName,
    currentCareProvider,
    cancelApptsInNextDay,
    patientData,
    slot,
    newNpi,
    carrier,
    paymentMethod,
    cancellationFee,
    appointments,
    willingToSee,
  } = args;
  const { patient_record_uuid } = patientData;
  const body: RematchRequestBody = {
    therapy_type: therapyType,
    patient_record_uuid,
    old_provider_npi: currentCareProvider.npi,
    new_provider_npi: newNpi,
    slot,
    cancel_appointments_in_next_day: !!cancelApptsInNextDay,
    willing_to_see: willingToSee,
  };
  const appointmentInNextDay =
    currentCareProvider &&
    findAppointmentInNextDay(appointments, currentCareProvider.npi);
  if (typeof cancelApptsInNextDay === 'undefined' && appointmentInNextDay) {
    // if there's an appointment in the next day and we haven't asked if the
    // user wants to cancel it, display modal to ask
    const closeModal = () => dispatch(actions.setModal(null));

    const trackModalEvent = (
      userContinued = false,
      userCancelled: boolean | null = null,
    ) => {
      trackEvent({
        product_area: 'Rematch24HourCancellationWarningModal',
        name: 'modal_view',
        trigger: 'Interaction',
        metadata: {
          proposed_appointment_time_local: dayjs.unix(slot).format(),
          insurance_carrier:
            carrier && paymentMethod === 'Health Insurance' ? carrier : 'self',
          provider_npi: newNpi.toString(),
          within_24_hours_continued: userContinued,
          cancelled_appointment: userCancelled,
        },
      });
    };

    dispatch(
      actions.setModal({
        children: (
          <ReplaceProviderCancellationPromptModal
            provider={currentCareProvider}
            appointmentInNextDay={appointmentInNextDay}
            onClose={closeModal}
            onContinue={(shouldCancel) => {
              closeModal();
              void onConfirm({
                ...args,
                cancelApptsInNextDay: shouldCancel,
              });
            }}
            cancellationFee={cancellationFee}
            trackModalEvent={trackModalEvent}
          />
        ),
      }),
    );
    return;
  }

  const therapyHeader = therapyTypeHeader[therapyType];
  const request = fetch.json('/api/rematch', {
    method: 'POST',
    body,
  });
  setProcessing(true);
  try {
    await request;
  } catch (r) {
    let errorMessage = (
      <>
        We are unable to replace your {therapyHeader} at this time. Please
        contact us at <SupportLink />.
      </>
    );
    let destinationRoute = '/care-team';
    let variant: ToastProps['variant'] = 'warning';
    const base = `/care-team/${body.old_provider_npi}/${body.therapyType}/replace`;

    const errorResponse = r as Response;

    // in case r is ever something other than a Response
    if (errorResponse?.status) {
      const { message } = await errorResponse.json();
      if (
        errorsFindingOldAppointment.find((errorText) =>
          message.includes(errorText),
        )
      ) {
        errorMessage = <>{toastDidntWorkTryAgain}</>;
        destinationRoute = '/care-team';
      } else if (message.includes(errorSlotNotAvailable)) {
        errorMessage = <>{toastSessionNotAvailable}</>;
        destinationRoute = `${base}/${body.new_provider_npi}/choose-appointment`;
      } else if (message.includes(errorRematchSameProvider)) {
        errorMessage = (
          <>
            Unable to replace provider with themselves. Select another provider
            or reschedule with this one.
          </>
        );
        destinationRoute = `${base}/find-new-provider`;
      } else if (message.includes(errorNotWillingToSee)) {
        errorMessage = (
          <>
            {providerName} does not provide {therapyType} therapy. Please choose
            a different provider.
          </>
        );
        destinationRoute = `${base}/find-new-provider`;
      } else if (message.includes(errorCreatingHold)) {
        errorMessage = <>{toastErrorBookingSession}</>;
        destinationRoute = `${base}/${body.new_provider_npi}/confirm-appointment/${body.slot}`;
      } else if (
        errorsCancelingHold.find((errorText) => message.includes(errorText))
      ) {
        errorMessage = (
          <>
            Your provider has been replaced.
            <br />
            Your old {therapyHeader} may continue to appear on the care team
            page for a short time. Please contact <SupportLink /> if you have
            any questions.
          </>
        );
        destinationRoute = '/care-team';
        variant = 'info';
      }
    }

    // Reload appt, event, and provider info in case it was stale before or the state changed during the PPB call
    dispatch(
      actions.async.setLoading({
        key: 'appointments_v2',
        loadingState: 'needed',
      }),
    );
    dispatch(
      actions.async.setLoading({
        key: 'careTeam_v3',
        loadingState: 'needed',
      }),
    );

    navigate(destinationRoute);

    dispatch(
      actions.setToast({
        text: <Text.P>{errorMessage}</Text.P>,
        variant: variant,
        onClose: () => dispatch(actions.setToast(null)),
      }),
    );
    return;
  } finally {
    setProcessing(false);
  }

  // If rematch was successful, reload appt, event, and provider info so the care team page will be accurate
  // todo, what do we actually have to refresh
  dispatch(
    actions.async.setLoading({
      key: 'appointments_v2',
      loadingState: 'needed',
    }),
  );
  dispatch(
    actions.async.setLoading({
      key: 'careTeam_v3',
      loadingState: 'needed',
    }),
  );
  dispatch(
    actions.setToast({
      text: (
        <Text.P>
          Your {therapyHeader.toLowerCase()} has been replaced. Your care team
          will update shortly.
        </Text.P>
      ),
      variant: 'success',
      onClose: () => dispatch(actions.setToast(null)),
    }),
  );
  navigate('/care-team');
};

export default function ConfirmAppoinmentV2() {
  const {
    appointment,
    providerNpi: currentProviderNpi,
    newNpi,
    therapyType,
  } = useParams<{
    appointment: string;
    providerNpi: string;
    newNpi: string;
    therapyType: TherapyType;
  }>();
  const location = useLocation();
  const maybeProvider = isProviderDetail(location.state?.provider)
    ? location.state.provider
    : null;
  const { data, WithData } = useData([
    'patientData',
    'insurance',
    'appointments_v2',
    'selfPayRates',
    'profile',
  ]);
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const [processing, setProcessing] = useState(false);

  if (!newNpi || !appointment) {
    return <RedirectToCareTeamOnErrorV2 />;
  }

  return (
    <WithProviderFromCareTeamV2 providerNpi={currentProviderNpi}>
      {({ provider: current }) => {
        const currentCareProvider = current as ActiveProviderShapeV3;
        if (!therapyType) {
          return <RedirectToCareTeamOnErrorV2 />;
        }

        return (
          <WithData data={data}>
            {({
              patientData,
              insurance,
              selfPayRates,
              appointments_v2: appointments,
              profile,
            }) => (
              <WithProvider
                passthrough={maybeProvider}
                providerNpi={newNpi}
                patientRecordUuid={patientData.patient_record_uuid}
              >
                {(provider) => (
                  <WithPageState page="care-team">
                    {({ pageState }) => {
                      const { carrier, payment_method } = insurance;
                      const slot = Number(appointment);

                      const hasAppointmentInNextDay =
                        currentCareProvider &&
                        !!findAppointmentInNextDay(
                          appointments.rows,
                          currentCareProvider.npi,
                        );

                      const age = getAgeFromBirthdate(profile.birthdate);

                      useEffect(() => {
                        const originalAppointmentWithProvider =
                          currentCareProvider &&
                          getNextActiveAppointmentV2(
                            appointments.rows,
                            currentCareProvider.npi,
                          );

                        const metadata: { [x: string]: JSONValue } = {
                          proposed_provider_npi: newNpi,
                          original_appointment_time_local:
                            originalAppointmentWithProvider?.start_time ?? null,
                          proposed_appointment_time_local: dayjs
                            .unix(slot)
                            .format(),
                          insurance_carrier:
                            carrier && payment_method === 'Health Insurance'
                              ? carrier
                              : 'self',
                          provider_npi: newNpi.toString(),
                          original_provider_npi: currentProviderNpi as string,
                        };
                        trackEvent({
                          product_area: 'AddCareTeamConfirmationPage',
                          name: 'page_view',
                          trigger: 'Page load',
                          metadata,
                        });
                      }, []);

                      return (
                        <div className="space-y-10">
                          <FullWidthHeader>
                            Confirm your appointment
                          </FullWidthHeader>
                          <div className="lg:flex relative justify-start items-start gap-x-6 text-left">
                            <div className="w-full space-y-5">
                              <div className="border border-tertiary-2 rounded-2 px-6 pt-4 pb-8 w-full">
                                <ProviderLockup
                                  pageState={pageState}
                                  provider={provider}
                                />
                                <div className="w-full space-y-4 pt-4">
                                  <div className="flex items-center space-x-2">
                                    <CalendarIcon />
                                    <Text.P>
                                      {dayjs
                                        .unix(Number(appointment))
                                        .format('ddd MMM D [at] h:mmA')}
                                    </Text.P>
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <Clock />
                                    <Text.P>60 minutes</Text.P>
                                  </div>
                                  <div className="flex items-center space-x-2">
                                    <Video />
                                    <Text.P>Video session</Text.P>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full">
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    onConfirm({
                                      slot,
                                      newNpi: Number(newNpi),
                                      therapyType,
                                      patientData,
                                      dispatch,
                                      navigate,
                                      setProcessing,
                                      currentCareProvider,
                                      providerName:
                                        provider.first_name +
                                        ' ' +
                                        provider.last_name,
                                      carrier,
                                      paymentMethod: payment_method,
                                      cancellationFee: makeDollars(
                                        selfPayRates.data.therapy_cancel,
                                      ),
                                      appointments: appointments.rows,
                                      willingToSee:
                                        getWillingToSeeFromTherapyType(
                                          therapyType,
                                          age,
                                        ),
                                    })
                                  }
                                  state={processing ? 'waiting' : ''}
                                >
                                  Book
                                </Button>
                              </div>
                            </div>
                            <div className="w-full lg:w-1/2 xl:w-[26.25rem] xl:flex-none mt-5 lg:mt-0">
                              <div className="bg-quaternary-0 border-1 border-tertiary-2 px-8 py-16 relative rounded-2 space-y-5">
                                <div className="pl-3 mt-4 mb-12">
                                  <Calendar />
                                </div>
                                <Text.H4>Cancellation reminder</Text.H4>
                                {hasAppointmentInNextDay ? (
                                  <Text.P>
                                    You have an appointment with your existing
                                    provider within the next 24 hours. You will
                                    have the option to either keep or cancel
                                    this next appointment. If you cancel, you
                                    will be charged a cancellation fee. All
                                    later appointments with your existing
                                    provider will be canceled.
                                  </Text.P>
                                ) : (
                                  <Text.P>
                                    Replacing your provider and booking this
                                    appointment will cancel all future
                                    appointments with your existing provider.
                                  </Text.P>
                                )}{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </WithPageState>
                )}
              </WithProvider>
            )}
          </WithData>
        );
      }}
    </WithProviderFromCareTeamV2>
  );
}
