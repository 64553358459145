import {
  stringsToAllCaps,
  stringsToLowercase,
  usStateAbbreviations,
  customInsuranceCapitalizationMap,
} from './constants';

export const expirationMmYy = (v: string) =>
  v.replace(
    /^(\d{1,2})?(\/)?(\d{1,2})?(.*)$/g,
    (_match, mm = '', slash, yy) => {
      return `${mm || ''}${(slash && mm.length === 2) || yy ? '/' : ''}${
        yy || ''
      }`;
    },
  );

export const mmDdYyyy = (v: string) =>
  v.replace(
    /^(\d{1,2})?(\/)?(\d{1,2})?(\/)?(\d{1,4})?(.*)$/g,
    (_match, mm = '', slash1, dd = '', slash2, yyyy) => {
      return `${mm}${(slash1 && mm.length) || dd.length ? '/' : ''}${dd}${
        (slash2 && dd.length) || yyyy ? '/' : ''
      }${yyyy || ''}`;
    },
  );

export const phone = (v: string) => {
  if (!v) {
    return '';
  }
  return v.replace(
    /^(\()?(\d{1,3})?(\)\s?)?(\d{1,3})?(-)?(\d{1,4})?(.*)$/,
    (_match, openP, area = '', closeP, next3 = '', dash, last4 = '') => {
      return `${openP || area.length ? '(' : ''}${area}${
        (closeP && area.length === 3) || next3.length ? ') ' : ''
      }${next3}${
        (dash && next3.length === 3) || last4.length ? '-' : ''
      }${last4}`;
    },
  );
};

export const makeDollars = (v: number) => {
  return `$${v}`;
};

export const makeCorrectCasing = (str?: string, titlecase = false): string => {
  if (!str) {
    return '';
  }

  // Start with a consistent format
  str = str.toLowerCase().trim();

  // Replace underscores, condense duplicate whitespace
  str = str.replace(/_/g, ' ');
  str = str.replace(/[\s]+/g, ' ').trim();

  if (titlecase) {
    str =
      str in customInsuranceCapitalizationMap
        ? customInsuranceCapitalizationMap[str]
        : str
            .split(' ')
            .map((word) => capitalize(word))
            .join(' ');
  } else {
    str = capitalize(str);
  }

  // Replace presumed-acronyms [short words in ()] and PTSD with all caps
  str = str.replace(/\([a-z]{1,4}\)|ptsd/g, function (m) {
    return m.toUpperCase();
  });

  return str;
};

export function capitalize(str: string): string {
  if (!str) return '';

  // Handle known acronyms, conjunctions, and states
  if (stringsToLowercase.includes(str)) return str;
  if (stringsToAllCaps.includes(str)) {
    return str.toUpperCase();
  }
  // TODO Sylvie - handle things in parentheses better - '(southern ca)' is coming out '(Southern Ca)'
  if (usStateAbbreviations.includes(str.toUpperCase()))
    return str.toUpperCase();

  return str.replace(/\w/, function (m) {
    return m.toUpperCase();
  });
}

export const capitalizeName = (name: string) => {
  const words = name.trim().split(/\s+/);
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  const capitalizedFullName = capitalizedWords.join(' ');

  return capitalizedFullName;
};
