import { useContext, useEffect } from 'react';
import { StateContext } from '@/App';
import { useFlagCheck } from './use-feature-flags';
import actions from '@/state/actions';
import MaintenanceBanner from '@/components/MaintenanceBanner';

export const showMaintenanceBanner = (): boolean => {
  const { isAllowed } = useFlagCheck();
  return isAllowed({
    patientPortalShowMaintenanceBanner: true,
  });
};

export const useMaintenanceBanner = (maintenanceBanner = false) => {
  const { dispatch } = useContext(StateContext);

  useEffect(() => {
    if (maintenanceBanner) {
      dispatch(
        actions.setBanner({
          children: <MaintenanceBanner />,
        }),
      );
    } else if (!maintenanceBanner) {
      dispatch(actions.setBanner(null));
    }
  }, [maintenanceBanner]);
};
