import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {
  AppointmentParticipant,
  ProviderDetail,
  TherapyType,
  SeriesType,
  AppointmentV2,
} from './types';

dayjs.extend(isSameOrAfter);

export const getPatientFromParticipants = (
  participants: [AppointmentParticipant],
): AppointmentParticipant | undefined =>
  participants.find(({ role }) => role === 'primary');

export const transformPatientUuidToPatientRef = (uuid: string): string => {
  return `patient/patients/${uuid}`;
};

export const getBookableSlots = (
  usedAvailabilitySlots: Record<number, number[]> = {},
  npi: number,
  bookableSlots: number[],
) => {
  const usedAvailabilitySlotsForProvider = usedAvailabilitySlots[npi];
  if (!usedAvailabilitySlotsForProvider) {
    return bookableSlots;
  }

  return bookableSlots.filter(
    (slot) => !usedAvailabilitySlotsForProvider.includes(slot),
  );
};

// Appointment therapy_type and series_type determines which bookable slot array to use.
export const getBookableSlotsForProvider = (
  usedAvailabilitySlots: Record<number, number[]> = {},
  provider: ProviderDetail,
  therapyType: TherapyType,
  seriesType: SeriesType,
  isHold = false,
) => {
  let bookableSlots = provider.bookable_slots;
  if (therapyType === 'psychiatric' && seriesType === 'followup') {
    // Fallback to empty array if bookable_followup_slots is undefined
    bookableSlots = provider.bookable_followup_slots || [];
  } else if (therapyType !== 'psychiatric' && !isHold) {
    // For now old code returns bookable_followup_slots as an empty array for non-psych
    // providers but new code will have more availability for followup appointments
    // so check if bookable_follow_slots has any values and use that then fallback to the
    // bookable_slots (this is different than for psych since those slots are blocked different)
    bookableSlots = provider.bookable_followup_slots?.length
      ? provider.bookable_followup_slots
      : provider.bookable_slots;
  }
  return getBookableSlots(usedAvailabilitySlots, provider.npi, bookableSlots);
};

export const therapyTypeToInitialAppointmentDescription = (
  therapyType: TherapyType,
): string | undefined => {
  const map: Partial<Record<TherapyType, string>> = {
    individual: 'Initial therapy',
    couples: 'Initial couples therapy',
    family: 'Initial family therapy',
    psychiatric: 'Initial psychiatry appointment',
  };
  return map[therapyType];
};

export const getAppointmentsWithin48Hr = (
  appointments: AppointmentV2[],
  now: dayjs.Dayjs,
): string[] => {
  return appointments
    .filter(({ start_time }) => {
      const startTime = dayjs(start_time);
      return (
        startTime.isSameOrAfter(now.subtract(1, 'hour')) &&
        startTime.diff(now, 'hour') <= 48
      );
    })
    .map(({ uuid }) => uuid);
};
