import Text from '@/components/Text';
import AppointmentPicker from '@/pages/care-team/AppointmentPicker';
import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import { providerLicenses, supportEmailPsych } from '@/utils/constants';
import FullWidthHeader from '../FullWidthHeader';
import Clock from '../icons/Clock';
import Video from '../icons/Video';
import AdditionalAssistanceFooter from './AdditionalAssistanceFooter';
import OldAppointmentCard from './OldAppointmentCard';
import {
  ActiveProviderShapeV3,
  InactiveProviderShape,
  OldAppointmentProps,
  SeriesType,
  TherapyType,
} from '@/utils/types';
import InlineMessageBox from '../InlineMessageBox';
import SupportLink from '@/components/SupportLink';

type ChooseAppointmentProps = {
  provider: ActiveProviderShapeV3 | InactiveProviderShape;
  hasBookableAppointments: boolean;
  therapyType: TherapyType;
  seriesType: SeriesType;
  appointmentLengthInMinutes: number;
  oldAppointmentProps?: OldAppointmentProps;
  isHold: boolean;
  isReactivation?: boolean;
  onSendMessage?: (message: string) => unknown;
};

const ChooseAppointment = ({
  provider,
  hasBookableAppointments,
  therapyType,
  seriesType,
  appointmentLengthInMinutes,
  oldAppointmentProps,
  isHold,
  isReactivation,
  onSendMessage = () => {},
}: ChooseAppointmentProps) => {
  const isPsych = provider.role === 'PRESCRIBER';
  return (
    <div className="space-y-10">
      {/* Header/navigation area */}
      <FullWidthHeader returnPath="/appointments" returnPage="appointments">
        Choose a new appointment
      </FullWidthHeader>

      {/* Body */}
      <div className="flex flex-wrap justify-center lg:flex-nowrap w-full lg:space-x-8">
        {/* Select new appointment */}
        <div className="border-1 border-tertiary-2 rounded-2 flex-auto px-6 py-8 w-full lg:max-w-[715px]">
          {/* Provider header */}
          <div className="flex relative justify-start items-center gap-x-6">
            <ProviderHeadshot
              profileImage={provider.profile_image_s3_url}
              className="block w-20 h-20 self-start mb-4"
            />
            <div className="w-full">
              <div className="font-semibold mt-0 mb-3 text-xl text-left">
                <Text.H3 className="text-tertiary-7 mb:2">
                  {provider.first_name} {provider.last_name}
                </Text.H3>
                <Text.Small className="text-tertiary-5 pb-4">
                  {providerLicenses[provider.licenses[0]]?.name || ''}
                </Text.Small>
                {/* TODO: handle multiple licenses */}
              </div>
            </div>
          </div>

          {hasBookableAppointments ? (
            <>
              <div className="border-b border-t border-tertiary-2">
                <AppointmentPicker
                  generateLinkProps={(newAppointment) => ({
                    to: `${newAppointment}${
                      isReactivation ? '?reactivate=true' : ''
                    }`,
                  })}
                  provider={provider}
                  therapyType={therapyType}
                  seriesType={seriesType}
                  isHold={isHold}
                />
              </div>
              <div className="flex items-left space-x-5 text-tertiary-7 mt-4">
                <div className="flex items-center space-x-2">
                  <Clock />
                  <Text.P>{appointmentLengthInMinutes} minutes</Text.P>
                </div>
                <div className="flex items-center space-x-2">
                  <Video />
                  <Text.P>Video session</Text.P>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-start justify-center border-t border-tertiary-2 pt-4">
              {isPsych ? (
                <>
                  <Text.P>
                    {provider.first_name} has no upcoming availability
                  </Text.P>
                  <Text.P>
                    For assistance contact{' '}
                    <SupportLink
                      email={supportEmailPsych}
                      text={supportEmailPsych}
                    />
                  </Text.P>
                </>
              ) : (
                <>
                  <Text.Small className="py-4">
                    {provider.first_name} has no upcoming availability. Message
                    them to inquire.
                  </Text.Small>
                  <InlineMessageBox onSendMessage={onSendMessage} />
                </>
              )}
            </div>
          )}
        </div>

        {/* Old appointment info */}
        {oldAppointmentProps && (
          <OldAppointmentCard
            startTime={oldAppointmentProps.startTime}
            isFirstAppointment={oldAppointmentProps.isFirstAppointment}
            therapyType={therapyType}
            appointmentType={oldAppointmentProps.appointmentType}
            previousLengthInMinutes={
              oldAppointmentProps.previousLengthInMinutes
            }
          />
        )}
      </div>

      {/* Footer */}
      {hasBookableAppointments && (
        <div className="flex justify-center">
          <AdditionalAssistanceFooter />
        </div>
      )}
    </div>
  );
};

export default ChooseAppointment;
