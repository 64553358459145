import { useContext, useEffect } from 'react';
import Text from '@/components/Text';
import actions from '@/state/actions';
import AccountCard, { NewAccountCard } from './AccountCard';
import VerifyPatientRelationship from './VerifyPatientRelationship';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { Logo } from '@/components/assets';
import Loader from '@/components/Loader';
import Toast from '@/components/Toast';
import fetch from '@/utils/fetch';
import { useLogout, useRefreshSession } from '../utils/session';
import { StateContext } from '@/App';
import { Loaded, ProfileDetails } from '@/utils/types';

const SelectAccount = () => {
  const { state, dispatch } = useContext(StateContext);
  const { accounts } = state.session;
  const logout = useLogout(dispatch);
  const refreshSession = useRefreshSession(dispatch);

  useEffect(() => {
    refreshSession();
  }, []);

  const openLinkAccountModal = () =>
    dispatch(
      actions.setModal({
        children: (
          <VerifyPatientRelationship state={state} dispatch={dispatch} />
        ),
      }),
    );

  const onAccountSelect = (patientRecordUuid: string) => {
    const selectedAccount = accounts?.find(
      (a) => a.patient_record_uuid === patientRecordUuid,
    );
    if (selectedAccount && !selectedAccount.expired) {
      const {
        advanced_md_office_key,
        salesforce_contact_id,
        patient_record_uuid,
        patient_uuid,
        acting_agent_uuid,
        access_type,
      } = selectedAccount;
      fetch
        .json('/api/set_selected_account', {
          method: 'POST',
          body: {
            advanced_md_office_key,
            salesforce_contact_id,
            patient_record_uuid,
            patient_uuid,
            acting_agent_uuid,
            access_type,
          },
        })
        .then(() => {
          dispatch(actions.setAccountSelected(selectedAccount));
          window.location.href = window.location.pathname || '/';
        })
        .catch(() => {
          dispatch(
            actions.setToast({
              text: <Text.P>Failed to select account.</Text.P>,
              variant: 'warning',
              onClose: () => dispatch(actions.setToast(null)),
            }),
          );
        });
    }
  };

  const onCloseExpiredClick = (patientRecordUuid: string) => {
    if (accounts) {
      const expiredAccount = accounts?.find(
        (a) => a.patient_record_uuid === patientRecordUuid,
      );

      if (expiredAccount?.expired) {
        const { patient_record_uuid } = expiredAccount;
        fetch
          .json('/api/remove_patient_relationship', {
            method: 'POST',
            body: {
              patient_record_uuid,
            },
          })
          .then(() => {
            dispatch(
              actions.setRelationshipAccounts(
                accounts.filter(
                  (acc) => acc.patient_record_uuid !== patient_record_uuid,
                ),
              ),
            );
          })
          .catch(() => {
            dispatch(
              actions.setToast({
                text: <Text.P>Failed to remove patient account.</Text.P>,
                variant: 'warning',
                onClose: () => dispatch(actions.setToast(null)),
              }),
            );
          });
      }
    }
  };

  // still loading accounts
  if (typeof accounts === 'undefined') {
    return <Loader.Contained />;
  }

  const sortedAccounts = [...accounts].sort((a) => {
    return !a.expired && a.access_type === 'self' ? -1 : 1;
  });

  return (
    <div className={state.ui.modal ? 'overflow-y-hidden h-screen' : ''}>
      <div className="flex justify-between items-center mt-5 mx-5 md:mt-8 md:ml-8">
        <Logo />
        <div className="w-[100px] inline-flex" onClick={logout}>
          <Button variant="primary-outline">Log out</Button>
        </div>
      </div>
      <div className="text-center flex flex-col gap-10 pr-4 pl-4 items-center mt-16 md:mt-[125px]">
        <Text.Serif.H1.Light>Choose a profile</Text.Serif.H1.Light>
        {sortedAccounts?.length ? (
          <>
            <div className="flex flex-col flex-wrap justify-center md:flex-row">
              {sortedAccounts.map((account, idx) => {
                const {
                  patient_first_name,
                  patient_last_name,
                  patient_record_uuid,
                  expired,
                  patient_uuid,
                } = account;
                const preferredNameProp =
                  patient_uuid ===
                  (state.data.profile as Loaded<ProfileDetails>).uuid
                    ? (state.data.profile as Loaded<ProfileDetails>)
                        .preferred_name
                    : '';
                return (
                  <AccountCard
                    key={idx}
                    patientRecordUuid={patient_record_uuid}
                    relationship={expired ? undefined : account.access_type}
                    firstName={patient_first_name}
                    lastName={patient_last_name}
                    preferredName={preferredNameProp}
                    isExpired={expired}
                    onClick={expired ? undefined : onAccountSelect}
                    onCloseExpiredClick={
                      expired ? onCloseExpiredClick : undefined
                    }
                  />
                );
              })}
            </div>
            <Text.P className="text-tertiary-5 pb-4">
              If you manage care for someone else <br></br>
              <span
                className="text-primary-3 no-underline cursor-pointer mb-12"
                onClick={openLinkAccountModal}
              >
                Link their profile
              </span>
            </Text.P>
          </>
        ) : (
          <NewAccountCard onAddAccountClick={openLinkAccountModal} />
        )}
        {state.ui.modal && (
          <div className="w-full fixed h-screen top-0 left-0 z-10 flex justify-center items-center">
            <div
              className="absolute w-full h-full top-0 left-0 bg-tertiary-7 bg-opacity-50 z-0"
              onClick={() => dispatch(actions.setModal(null))}
            ></div>
            <div className="z-10">
              <Modal {...state.ui.modal} />
            </div>
          </div>
        )}
      </div>
      {state.toast && (
        <div className="w-screen flex justify-center fixed top-12 mb-4 z-20">
          <Toast {...state.toast} />
        </div>
      )}
    </div>
  );
};

export default SelectAccount;
