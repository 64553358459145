import { Dispatch, useContext, useMemo } from 'react';
import actions, { Action } from '../state/actions';
import getCookies from './cookies';
import dayjs from 'dayjs';
import fetch from './fetch';
import { StateContext } from '../App';

import {
  RelationshipAccounts,
  SelectedAccount,
  ActiveRelationshipAccount,
} from './types';
import setSelfOnSelectedAccountCookie from '@/utils/set-self-account';

// looks at whoami cookie and sets react session state
export const useSetSession = (dispatch: Dispatch<Action>) => {
  return () => {
    const whoamiCookie = getCookies()['__Host-whoami'];
    if (whoamiCookie) {
      const whoami: { email: string } = JSON.parse(whoamiCookie);
      const expires = Number(getCookies()['__Host-timeout']);
      dispatch(
        actions.setSession({
          email: whoami.email,
          expires,
        }),
      );
    } else {
      dispatch(actions.setSession({}));
    }
  };
};

export const useLoginAfterVerification = (dispatch: Dispatch<Action>) => {
  return async () => {
    // set session has to come first before getting relationship accounts as relationship accounts lives on auth state
    const setSession = useSetSession(dispatch);
    setSession();

    const accounts: RelationshipAccounts = await fetch
      .json('/api/get_relationship_accounts', {
        method: 'GET',
      })
      .catch(() => {
        dispatch(
          actions.async.setLoading({
            key: 'patientData',
            loadingState: 'error',
          }),
        );
      });
    await maybeSetSelectedAccount(accounts, dispatch);
    dispatch(actions.setRelationshipAccounts(accounts));
  };
};

export const maybeSetSelectedAccount = async (
  accounts: RelationshipAccounts,
  dispatch: Dispatch<Action>,
) => {
  // auto log user in if selected account lives on cookie & has the properties we need
  if (getCookies()['__Host-selectedAccount']) {
    const selectedAccount: SelectedAccount = JSON.parse(
      getCookies()['__Host-selectedAccount'],
    );

    if (selectedAccount.patient_uuid && selectedAccount.salesforce_contact_id) {
      dispatch(actions.setAccountSelected(selectedAccount));
      return;
    }
  }

  if (
    accounts?.length === 1 &&
    'access_type' in accounts[0] &&
    (accounts[0].access_type === 'self' ||
      accounts[0].access_type === 'minor_13_17')
  ) {
    const firstAccountInList = accounts[0];
    const {
      patient_record_uuid,
      patient_uuid,
      advanced_md_office_key,
      salesforce_contact_id,
      acting_agent_uuid,
      access_type,
    } = firstAccountInList;
    const selectedAccount: SelectedAccount = {
      patient_record_uuid,
      patient_uuid,
      advanced_md_office_key,
      salesforce_contact_id,
      acting_agent_uuid,
      access_type,
    };
    await setSelfOnSelectedAccountCookie(selectedAccount);
    dispatch(actions.setAccountSelected(selectedAccount));
  }
};

const logout = (onLogout: () => unknown) => () =>
  fetch('/session', { method: 'DELETE' }).finally(() => {
    onLogout();
  });

export const useLogout = (dispatch: React.Dispatch<Action>) => {
  return logout(() => dispatch(actions.setSession({})));
};

export const useTimeout = (dispatch: React.Dispatch<Action>) => {
  return logout(() => dispatch(actions.timeoutSession()));
};

export const useSession = () => {
  const { state } = useContext(StateContext);

  // time left in session. subtract 30 seconds to give buffer in case of unsynchronized clocks
  const timeLeft = Math.max(
    // Math.floor((state.session.expires - dayjs().valueOf()) / 1000) - 14060,
    Math.floor((state.session.expires - dayjs().valueOf()) / 1000) - 30,
    0,
  );

  const timeLeftText = useMemo(() => {
    return timeLeft > 90
      ? `${Math.ceil(timeLeft / 60)} minutes`
      : `${timeLeft} seconds`;
  }, [timeLeft]);

  return { timeLeft, timeLeftText };
};

export const useRefreshSession = (dispatch: React.Dispatch<Action>) => {
  return () => {
    void fetch('/session/refresh', { method: 'POST' }).then(() => {
      const expires = Number(getCookies()['__Host-timeout']);
      dispatch(actions.refreshSessionTime(expires));
    });
  };
};

export const getPatientUuidFromSelectedAccount = () => {
  const selectedAccountCookie = getCookies()['__Host-selectedAccount'];
  const selectedAccount: ActiveRelationshipAccount = JSON.parse(
    selectedAccountCookie,
  );
  const { patient_uuid } = selectedAccount;
  return patient_uuid;
};
