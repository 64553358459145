import { SVGProps } from 'react';

const AbstractMushroomArt = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="141"
      height="83"
      viewBox="0 0 141 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.605 5.42188V58.6429"
        stroke="#DDD9EE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1448 2.25C41.1684 2.25 38.4608 4.01536 37.1993 6.77859L31.9731 18.2252C31.9334 18.3122 31.8965 18.3997 31.8625 18.4874C31.1053 18.9804 30.3546 19.4865 29.6062 19.9912C28.7813 20.5474 27.9591 21.1017 27.1341 21.6345C23.2712 24.129 24.9953 30.2495 29.5609 30.2495H58.729C63.2946 30.2495 65.0188 24.129 61.1558 21.6345C60.3308 21.1017 59.5087 20.5474 58.6838 19.9912C57.9352 19.4865 57.1844 18.9802 56.4271 18.4871C56.3931 18.3995 56.3562 18.3122 56.3165 18.2252L51.0904 6.77858C49.8288 4.01536 47.1213 2.25 44.1448 2.25Z"
        fill="#5937E2"
      />
      <circle cx="44.5" cy="7.5" r="1.5" fill="#DDD9EE" />
      <circle cx="39" cy="15.25" r="1" fill="#DDD9EE" />
      <circle cx="48.5" cy="14.5" r="2.5" fill="#DDD9EE" />
      <path
        d="M118.072 38.7734C108.281 41.2732 88.7 52.6052 88.7 77.9358"
        stroke="#DDD9EE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <circle cx="116.236" cy="38.1607" r="6.73103" fill="#5937E2" />
      <path
        d="M121.917 11.2305C109.769 17.5924 87.4512 38.6793 95.3698 72.1321"
        stroke="#DDD9EE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <circle
        cx="119.301"
        cy="10.9922"
        r="9.13497"
        transform="rotate(-13.3174 119.301 10.9922)"
        fill="#C8C0E8"
      />
      <path
        d="M83.375 82.25L0.375 82.25L0.375 80.75C0.375002 57.8302 18.9552 39.25 41.875 39.25C64.7948 39.25 83.375 57.8302 83.375 80.75L83.375 82.25Z"
        fill="#C8C0E8"
      />
      <path
        d="M131.665 82.5L66.2788 82.5L66.2788 81.7397C66.2788 63.6839 80.9159 49.0468 98.9717 49.0468C117.027 49.0468 131.665 63.6839 131.665 81.7397L131.665 82.5Z"
        fill="#C8C0E8"
      />
    </svg>
  );
};

export default AbstractMushroomArt;
