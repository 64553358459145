import { CareTeamV3, InsuranceDetails, AnalyticsEvent } from '@/utils/types';
import Text from './Text';
import X from './icons/X';
import useSortedProvidersAndHeadings from '@/utils/use-sort-providers-and-headings';
import { therapyTypeToVisitType } from '@/utils/constants';
import { getCareTypes } from '@/utils/provider';
import { useContext, useState } from 'react';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import SupportLink from './SupportLink';
import Tab from '@/components/Tab';
import AddCareTypeModal from '@/pages/care-team/AddCareTypeModal';
import { isKaiserPayerExcluded } from '@/utils/tools';
import trackEvent from '@/utils/amplitude';
import BookingModalCareProvider from '@/components/BookingModalCareProvider';

type TabValue = 'care-team' | 'past-providers';

interface BookingModalProps {
  careTeam: CareTeamV3;
  patientState: string;
  birthdate: string;
  insurance: InsuranceDetails;
}
const BookingModal = ({
  careTeam,
  patientState,
  birthdate,
  insurance,
}: BookingModalProps) => {
  const { dispatch } = useContext(StateContext);
  const { providersAndHeadings } = useSortedProvidersAndHeadings(
    careTeam.active,
    true,
  );
  const { providersAndHeadings: inactiveProvidersAndHeadings } =
    useSortedProvidersAndHeadings(careTeam.inactive, false);

  const { availableCareTypes, careTeamTypesArray, hidePsych } = getCareTypes({
    careTeam,
    insurance,
    birthdate,
    patientState,
  });
  const [activeTab, setActiveTab] = useState<TabValue>('care-team');

  const handleChangeTab = (value: TabValue) => {
    setActiveTab(value);
  };

  const onClose = () => {
    dispatch(actions.setModal(null));
  };

  const onAddProvider = () => {
    dispatch(
      actions.setModal({
        children: (
          <AddCareTypeModal
            existingCareTypes={careTeamTypesArray}
            availableCareTypes={availableCareTypes}
            hidePsych={hidePsych}
            trackNextClick={() => {}}
          />
        ),
      }),
    );
  };

  const onClickPastProviders = () => {
    setActiveTab('past-providers');
    firePastProvidersViewEvent();
  };

  const firePastProvidersViewEvent = () => {
    const viewPastProvidersEvent: AnalyticsEvent = {
      product_area: 'CareTeam',
      name: 'booking_modal_past_providers_view',
      trigger: 'Interaction',
      metadata: {
        existing_care_types: careTeamTypesArray,
        available_care_types: availableCareTypes,
      },
    };
    trackEvent(viewPastProvidersEvent);
  };

  const showActiveProviders = activeTab === 'care-team';
  const providerAndHeadingsList = showActiveProviders
    ? providersAndHeadings
    : inactiveProvidersAndHeadings;
  const noProviders = providerAndHeadingsList.length == 0;

  const disableAddCareType =
    availableCareTypes.length === 0 ||
    (isKaiserPayerExcluded(insurance) &&
      careTeamTypesArray.includes('psychiatric'));

  return (
    <div
      data-testid="booking-modal"
      className="h-screen w-screen overflow-auto rounded-2 p-5 bg-tertiary-0 md:h-auto md:w-[610px] md:max-h-[500px] md:p-6 scrollbar-hide"
    >
      <div className="flex flex-col justify-between align-top space-y-5">
        <div className="flex justify-between items-center">
          <Text.H4>Choose a provider to book with</Text.H4>
          <X role="img" onClick={onClose} className="cursor-pointer"></X>
        </div>
        <div className="flex md:flex-row md:justify-between md:items-center">
          <div className="flex gap-x-5 mb-2 md:mb-0">
            <Tab
              tab={{ name: 'Current providers', value: 'care-team' }}
              onClick={handleChangeTab}
              isActive={showActiveProviders}
            />
            <Tab
              tab={{ name: 'Past providers', value: 'past-providers' }}
              onClick={onClickPastProviders}
              isActive={!showActiveProviders}
            />
          </div>
          {disableAddCareType ? null : (
            <div className="flex gap-x-5 mb-2 md:mb-0">
              <Text.P.Bold
                onClick={onAddProvider}
                className="text-primary-3 cursor-pointer pb-2"
                role="button"
              >
                + Add provider
              </Text.P.Bold>
            </div>
          )}
        </div>
        {noProviders ? (
          <div className="text-center pb-[44px]">
            <Text.H4 className="mb-2">
              {showActiveProviders
                ? 'No current providers'
                : 'No past providers'}
            </Text.H4>
            <Text.P>
              Add a provider or contact <SupportLink />
            </Text.P>
          </div>
        ) : (
          <>
            <div className="space-y-4 basis-full w-full">
              {providerAndHeadingsList
                .sort((a, b) =>
                  a.heading.localeCompare(b.heading, undefined, {
                    sensitivity: 'base',
                  }),
                )
                .map(({ heading, providers }) => {
                  return (
                    <div key={heading}>
                      <div className="bg-gray-100 mb-6 px-3 py-1 rounded-3">
                        <Text.Small.Bold>
                          {therapyTypeToVisitType[heading]}
                        </Text.Small.Bold>
                      </div>
                      <div>
                        {providers.map((provider) => {
                          return (
                            <BookingModalCareProvider
                              key={provider.npi}
                              provider={provider}
                              patientState={patientState}
                              therapyType={heading}
                              isActiveProvider={showActiveProviders}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {noProviders ? null : (
        <Text.Small className="text-tertiary-5">
          For additional assistance contact <SupportLink />
        </Text.Small>
      )}
    </div>
  );
};

export default BookingModal;
