import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import Text from '../../components/Text';

const RedirectToCareTeamOnErrorV2 = (props: {
  onRedirect?: () => unknown;
  addCare?: boolean;
}) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const onRedirect =
    props.onRedirect ??
    (() => {
      dispatch(
        actions.async.setLoading({
          key: 'appointments_v2',
          loadingState: 'needed',
        }),
      );
      dispatch(
        actions.async.setLoading({
          key: 'careTeam_v3',
          loadingState: 'needed',
        }),
      );
      if (!props.addCare) {
        const copy = `An error occurred. We've refreshed the data, so please select a
          provider from your care team and try again.`;
        dispatch(
          actions.setToast({
            variant: 'warning',
            text: <Text.P>{copy}</Text.P>,
            onClose: () => dispatch(actions.setToast(null)),
          }),
        );
      }
    });
  useEffect(() => {
    const location = props.addCare
      ? '/care-team?addCare=any&providerRemoved=true'
      : '/care-team';
    navigate(location);
    onRedirect();
  }, []);
  return null;
};

export default RedirectToCareTeamOnErrorV2;
