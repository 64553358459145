import Dollar from '@/components/icons/Dollar';
import Text from '@/components/Text';

export const CashPaySection = ({
  isEditing = false,
}: {
  isEditing?: boolean;
}) => (
  <div className="border-1 p-4 rounded-2">
    {!isEditing && (
      <div className="flex items-center mb-4">
        <Text.P className="flex-1 text-sm">Current coverage</Text.P>
      </div>
    )}
    <div className="flex items-center">
      <Dollar />
      <span className="pl-2">Cash pay</span>
    </div>
  </div>
);
