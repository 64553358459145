import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import Text from '@/components/Text';
import useRefreshCareTeam from '@/utils/use-refresh-care-team';

const RedirectToAppointmentsError = (props: { onRedirect?: () => unknown }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const refreshCareTeam = useRefreshCareTeam(dispatch);
  const onRedirect =
    props.onRedirect ??
    (() => {
      refreshCareTeam();
      dispatch(
        actions.setToast({
          variant: 'warning',
          text: (
            <Text.P>
              An error occurred. We've refreshed the data, so please select an
              appointment again.
            </Text.P>
          ),
          onClose: () => dispatch(actions.setToast(null)),
        }),
      );
    });
  useEffect(() => {
    navigate('/appointments');
    onRedirect();
  }, []);
  return null;
};

export default RedirectToAppointmentsError;
